var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Ajv from "ajv";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import { BanafoModal, Button, Form } from "banafo-design-system";
import { hideUploadModal, selectUploadModalIsOpen } from "src/features/conversations/conversationsSlice";
import validateSchema from "src/features/auth/util/validateSchema";
import LanguageQualityMessage from "src/common/components/ui/LanguageQualityMessage";
import DatePickerInput from "src/common/components/ui/DatePickerInput";
import { CONVERSATION_DIRECTIONS_ENUM, CONVERSATION_TYPE_PHONE_CALL, CONVERSATION_TYPES_ENUM, FILE_UPLOAD_ACCEPTED_FORMATS, FILE_UPLOAD_FORMAT_LIST, FILE_UPLOAD_MAX_SIZE, FILE_UPLOAD_MIMETYPE_LIST } from "src/features/conversations/config";
import { CONVERSATION_TYPE_OTHER } from "src/features/auth/config/conversations";
import { useGetApiKeysSettingsQuery } from "src/features/apiKeys/apiKeysSlice";
import { useGetTranscriptLanguagesQuery } from "src/features/transcripts/transcriptsSlice";
import { Box } from "@mantine/core";
import UploadCard from "src/features/uploadRecording/UploadCard";
import { useUploadFileMutation } from "src/features/conversations/conversationApi";
const ajv = new Ajv({ allErrors: true, jsonPointers: true });
require('ajv-errors')(ajv /*, {singleError: true} */);
const fileType = {
    type: 'object',
    required: ["size", "type"],
    properties: {
        type: {
            enum: FILE_UPLOAD_MIMETYPE_LIST
        },
        size: {
            type: "number",
            maximum: 500 * 1024 * 1024
        }
    }
};
const fileSchema = {
    type: 'object',
    properties: {
        file: fileType
    },
    errorMessage: {
        properties: {
            file: 'errors:Invalid.file_',
        }
    },
};
const schema = {
    type: 'object',
    required: ["language"],
    properties: {
        "language": {
            type: ['string'],
            "minLength": 2,
        },
        file: fileType
    },
    errorMessage: {
        properties: {
            language: 'errors:Invalid.language_',
            file: 'errors:Invalid.file_',
        }
    },
};
const UploadRecordingModal = () => {
    var _a, _b;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const show = useSelector(selectUploadModalIsOpen);
    const initialFormState = {
        file: null,
        type: CONVERSATION_TYPE_OTHER,
        remotePhone: '',
        direction: 'inbound',
        startedAt: moment(),
        title: '',
        source: '',
        summary: '',
        language: '',
    };
    const [infoLanguage, setInfoLanguage] = useState('');
    const [inputs, setInputs] = useState(initialFormState);
    const [errors, setErrors] = useState({});
    const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation({ fixedCacheKey: "upload-recording" });
    const { data: languages = [], isLoading: isLoadingLanguages } = useGetTranscriptLanguagesQuery();
    const { data: { transcripts: { language } } = { transcripts: {} }, isLoading } = useGetApiKeysSettingsQuery();
    useEffect(() => {
        if (!language) {
            return;
        }
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { language })));
    }, [language]);
    const handleHideModal = () => {
        setInputs(Object.assign(Object.assign({}, initialFormState), { language: inputs.language }));
        setInfoLanguage('');
        setErrors({});
        dispatch(hideUploadModal());
    };
    const handleInputChange = (e) => {
        if (e.target.name === "language") {
            setInfoLanguage(e.target.value);
        }
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.value })));
    };
    const handleFileChange = (e) => {
        const { file } = errors, restErrors = __rest(errors, ["file"]);
        setErrors(restErrors);
        const { hasErrors, errors: validationErrors } = validateSchema(ajv, fileSchema, { file: e.target.files[0] }, t);
        if (hasErrors) {
            setErrors(validationErrors);
            return;
        }
        setInputs(inputs => (Object.assign(Object.assign({}, inputs), { [e.target.name]: e.target.files[0] })));
    };
    const handleUploadRecording = () => {
        setErrors({});
        const { hasErrors, errors } = validateSchema(ajv, schema, inputs, t);
        if (hasErrors) {
            setErrors(errors);
            return;
        }
        uploadFile(inputs)
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    const getSelectedDateTime = (datetime) => {
        return datetime ? new Date(datetime) : datetime;
    };
    return (_jsx(BanafoModal, { show: show, onHide: handleHideModal, title: isUploadingFile ? t("Uploading_") : t("Upload.recording_"), footer: !isUploadingFile &&
            _jsx(Button, { variant: "danger", onClick: handleUploadRecording, children: t("Upload_") }), "aria-labelledby": "contained-modal-title-vcenter", children: isUploadingFile ? _jsx(UploadingBody, {}) :
            _jsxs(Form, { children: [_jsxs(Form.Group, { children: [_jsx(Form.Label, { children: t('Recording_') }), _jsx(Form.Control, { type: "file", id: "custom-file", label: (_b = (_a = inputs.file) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : t('Recording_'), name: "file", isInvalid: !!errors.file, onChange: handleFileChange, accept: FILE_UPLOAD_ACCEPTED_FORMATS, feedback: errors.file }), _jsxs(Form.Text, { className: "text-muted", children: [FILE_UPLOAD_MAX_SIZE, " ", t('limit_'), ". ", t('Supported.formats_'), ": ", FILE_UPLOAD_FORMAT_LIST.join(', ')] })] }), _jsxs(Form.Group, { controlId: "title", children: [_jsx(Form.Label, { children: t('Title_') }), _jsx(Form.Control, { placeholder: t('Title_'), name: "title", onChange: handleInputChange, value: inputs.title, isInvalid: !!errors.title }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.title })] }), _jsxs(Form.Group, { controlId: "formSummary", children: [_jsx(Form.Label, { children: t('Summary_') }), _jsx(Form.Control, { as: "textarea", rows: 3, placeholder: t('Summary_'), name: "summary", onChange: handleInputChange, value: inputs.summary, isInvalid: !!errors.summary }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.summary })] }), _jsxs(Form.Group, { controlId: "type", children: [_jsx(Form.Label, { children: t('Conversation.type_') }), _jsx("div", { children: CONVERSATION_TYPES_ENUM.map(({ text, value }, index) => {
                                    return (_jsxs(Form.Check, { inline: true, id: index, children: [_jsx(Form.Check.Input, { type: 'radio', name: "type", value: value, onChange: handleInputChange, checked: value === inputs.type }), _jsx(Form.Check.Label, { children: text })] }, value));
                                }) })] }), _jsxs(Form.Group, { controlId: "upload-recording-language", children: [_jsx(LanguageQualityMessage, { language: infoLanguage }), _jsx(Form.Label, { children: t('Language_') }), _jsx(Form.Select, { placeholder: t('Language_'), name: "language", onChange: handleInputChange, value: inputs.language, isInvalid: !!errors.language, children: languages.map(({ code, name }, index) => _jsx("option", { value: code, children: name }, index)) }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.language })] }), _jsxs(Form.Group, { controlId: "formSource", children: [_jsx(Form.Label, { children: t('From.Source_') }), _jsx(Form.Control, { placeholder: t('Source_'), name: "source", onChange: handleInputChange, value: inputs.source, isInvalid: !!errors.source }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.source })] }), inputs.type === CONVERSATION_TYPE_PHONE_CALL && _jsxs(_Fragment, { children: [_jsxs(Form.Group, { controlId: "phone", children: [_jsx(Form.Label, { children: t('Phone_') }), _jsx(Form.Control, { placeholder: t('Phone_'), name: "remotePhone", onChange: handleInputChange, value: inputs.remotePhone, isInvalid: !!errors.remotePhone }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.remotePhone })] }), _jsxs(Form.Group, { controlId: "direction", children: [_jsx(Form.Label, { children: t('Direction_') }), _jsx("div", { children: CONVERSATION_DIRECTIONS_ENUM.map(({ text, value }, index) => {
                                            return (_jsxs(Form.Check, { inline: true, id: value, children: [_jsx(Form.Check.Input, { type: 'radio', name: "direction", value: value, onChange: handleInputChange, checked: value === inputs.direction }), _jsx(Form.Check.Label, { children: text })] }, index));
                                        }) })] })] }), _jsxs(Form.Group, { controlId: "startedAt", children: [_jsx(Form.Label, { children: t('Started.Datetime_') }), _jsx(DatePicker, { customInput: _jsx(DatePickerInput, {}), placeholderText: `${t('From_')}:`, selected: getSelectedDateTime(inputs.startedAt), onChange: date => setInputs(Object.assign(Object.assign({}, inputs), { startedAt: date ? moment(date) : null })), showTimeInput: true, dateFormat: "dd.MM.yyyy HH:mm", wrapperClassName: "d-block", className: classNames(!!errors.startedAt && "is-invalid", "form-control") }), _jsx(Form.Control.Feedback, { type: "invalid", children: errors.startedAt })] })] }) }));
};
const UploadingBody = () => {
    return (_jsx(Box, { py: "lg", children: _jsx(UploadCard, {}) }));
};
export default UploadRecordingModal;
