var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Form, Popover } from "banafo-design-system";
import { forwardRef, useState } from "react";
import { useUpdateCallMutation } from "src/features/conversations/conversationApi";
import { useParams } from "react-router-dom";
const AudioTitlePopover = forwardRef((_a, ref) => {
    var { title: callTitle, onHide } = _a, props = __rest(_a, ["title", "onHide"]);
    const { callId } = useParams();
    const [title, setTitle] = useState(callTitle);
    const [updateRecording] = useUpdateCallMutation();
    const handleChangeName = (e) => {
        const { value } = e.target;
        setTitle(value);
    };
    const handleKeyDown = (e) => __awaiter(void 0, void 0, void 0, function* () {
        if (e.key === "Enter" && title.trim().length > 0) {
            yield updateRecording({ id: callId, data: { title } });
            onHide();
        }
    });
    return (_jsx(Popover, Object.assign({ ref: ref }, props, { children: _jsx(Popover.Body, { children: _jsx(Form.Control, { type: "text", placeholder: "Title", value: title, onChange: handleChangeName, onKeyDown: handleKeyDown, autoFocus: true }) }) })));
});
export default AudioTitlePopover;
