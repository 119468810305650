import { createSelector, createSlice, isAllOf, isAnyOf } from "@reduxjs/toolkit";
import moment from "moment";
import { SORT_ASC, SORT_DESC } from "src/app/config";
import { conversationsReset, extendedApi, } from "src/features/conversations/conversationApi";
// SLICE
const matchConversationsReset = isAllOf(conversationsReset);
// interface FiltersState {
//   search: string;
//   startedAt: string | null;
//   endedAt: string | null;
//   directionInbound: boolean;
//   directionOutbound: boolean;
//   typeWebConference: boolean;
//   typeOther: boolean;
// }
//
// // Define a type that extracts only boolean properties
// type BooleanKeys<T> = {
//   [K in keyof T]: T[K] extends boolean ? K : never;
// }[keyof T];
//
// // Extract only boolean keys from FiltersState
// type ActiveFilters = BooleanKeys<FiltersState>;
//
// // Function to extract only active filters
// const getActiveFilters = (filters: FiltersState): ActiveFilters[] => {
//   return (Object.keys(filters) as (keyof FiltersState)[])
//     .filter((key): key is ActiveFilters => typeof filters[key] === "boolean" && filters[key] === true);
// };
//
// const filters: FiltersState = {
//   search: '',
//   startedAt: null,
//   endedAt: null,
//   directionInbound: true,
//   directionOutbound: true,
//   typeWebConference: true,
//   typeOther: false,
// };
//
// const activeFilters = getActiveFilters(filters);
// console.log(activeFilters);
// // Output: ["directionInbound", "directionOutbound", "typeWebConference"]
const initFilters = {
    search: '',
    startedAt: null,
    endedAt: null,
    directionInbound: true,
    directionOutbound: true,
    typeWebConference: true,
    typeOther: true,
    tags: []
};
const initAudioPlayer = {
    showNotes: true,
    showPois: true,
};
const initialSliceState = {
    page: 0,
    uploadModalOpen: false,
    uploadProgress: 0,
    uploadFileName: '',
    downloadTranscriptsModal: { isOpen: false, format: undefined },
    filters: initFilters,
    filtersHamburgerExpanded: false,
    activeCallId: null,
    selectedIds: [],
    editItemId: null,
    deleteItemId: null,
    editModalOpen: false,
    deleteModalOpen: false,
    bulkDeleteModalOpen: false,
    createContactModalOpen: false,
    existingContactModalOpen: false,
    audioPlayer: initAudioPlayer
};
export const conversationsSlice = createSlice({
    name: 'conversations',
    initialState: initialSliceState,
    reducers: {
        reset: () => initialSliceState,
        resetPage: state => {
            state.page = initialSliceState.page;
        },
        incrementPage: state => {
            state.page++;
        },
        showUploadModal: state => {
            state.uploadModalOpen = true;
        },
        hideUploadModal: state => {
            state.uploadModalOpen = false;
        },
        setUploadProgress: (state, action) => {
            const progress = action.payload;
            state.uploadProgress = progress;
        },
        setUploadFileName: (state, action) => {
            const name = action.payload;
            state.uploadFileName = name;
        },
        resetUpload: (state, action) => {
            state.uploadFileName = '';
            state.uploadProgress = 0;
        },
        showDownloadTranscriptsModal: (state, action) => {
            const { format } = action.payload;
            if (!format) {
                return;
            }
            state.downloadTranscriptsModal = { isOpen: true, format };
        },
        hideDownloadTranscriptsModal: state => {
            state.downloadTranscriptsModal = { isOpen: false, format: undefined };
        },
        changeFilter: (state, action) => {
            const { name, value, isTag } = action.payload;
            if (isTag) {
                if (value) {
                    if (!state.filters.tags.includes(name)) {
                        state.filters.tags.push(name);
                    }
                }
                else {
                    state.filters.tags = state.filters.tags.filter(tag => tag !== name);
                }
            }
            else {
                if (name === 'startedAt' && value) {
                    state.filters['endedAt'] = new Date(moment(value).add(2, 'days'));
                }
                else {
                    state.filters[name] = value;
                }
            }
            state.page = 0;
        },
        changeSortFilter: (state, action) => {
            const name = action.payload;
            const field = `sort[${name}]`;
            state.page = 0;
            if (!state.filters[field]) {
                state.filters[field] = SORT_ASC;
                return;
            }
            if (state.filters[field] === SORT_ASC) {
                state.filters[field] = SORT_DESC;
                return;
            }
            delete state.filters[field];
        },
        toggleFiltersHamburgerMenu: state => {
            state.filtersHamburgerExpanded = !state.filtersHamburgerExpanded;
        },
        showDeleteModal: (state, action) => {
            state.activeCallId = action.payload;
            state.deleteModalOpen = true;
        },
        hideDeleteModal: state => {
            state.activeCallId = null;
            state.deleteModalOpen = false;
        },
        toggleSelectedId(state, action) {
            const id = action.payload;
            if (state.selectedIds.includes(id)) {
                state.selectedIds = state.selectedIds.filter((existingId) => existingId !== id); // Remove if exists
            }
            else {
                state.selectedIds.push(id); // Add if not exists
            }
        },
        clearSelectedIds(state) {
            state.selectedIds = []; // Clear all selected ids
        },
        setSelectedIds(state, action) {
            const ids = action.payload;
            state.selectedIds = ids; // Clear all selected ids
        },
        showBulkDeleteModal: (state, action) => {
            state.bulkDeleteModalOpen = true;
        },
        hideBulkDeleteModal: state => {
            state.bulkDeleteModalOpen = false;
        },
        showEditModal: (state, action) => {
            state.activeCallId = action.payload;
            state.editModalOpen = true;
        },
        hideEditModal: state => {
            state.activeCallId = null;
            state.editModalOpen = false;
        },
        showCreateContactModal: (state, action) => {
            state.activeCallId = action.payload;
            state.createContactModalOpen = true;
        },
        hideCreateContactModal: state => {
            state.activeCallId = null;
            state.createContactModalOpen = false;
        },
        showExistingContactModal: (state, action) => {
            state.activeCallId = action.payload;
            state.existingContactModalOpen = true;
        },
        hideExistingContactModal: state => {
            state.activeCallId = null;
            state.existingContactModalOpen = false;
        },
        switchContactModals: state => {
            state.createContactModalOpen = !state.createContactModalOpen;
            state.existingContactModalOpen = !state.existingContactModalOpen;
        },
        audioPlayerToggleNotes: state => {
            state.audioPlayer.showNotes = !state.audioPlayer.showNotes;
        },
        audioPlayerTogglePois: state => {
            state.audioPlayer.showPois = !state.audioPlayer.showPois;
        },
    },
    extraReducers(builder) {
        builder
            .addMatcher(matchConversationsReset, (state, action) => {
            return initialSliceState;
        })
            .addMatcher(isAnyOf(extendedApi.endpoints.bulkDeleteCalls.matchFulfilled, changeFilter), (state, action) => {
            state.selectedIds = [];
        });
    },
});
export const { name, actions } = conversationsSlice;
export const { reset, resetPage, incrementPage, showUploadModal, hideUploadModal, setUploadProgress, setUploadFileName, resetUpload, showDownloadTranscriptsModal, hideDownloadTranscriptsModal, changeFilter, changeSortFilter, toggleFiltersHamburgerMenu, showDeleteModal, hideDeleteModal, toggleSelectedId, clearSelectedIds, setSelectedIds, showBulkDeleteModal, hideBulkDeleteModal, showEditModal, hideEditModal, showCreateContactModal, hideCreateContactModal, showExistingContactModal, hideExistingContactModal, switchContactModals, audioPlayerToggleNotes, audioPlayerTogglePois, } = conversationsSlice.actions;
export default conversationsSlice.reducer;
export const selectPage = state => state[name].page;
export const selectFilters = state => state[name].filters;
export const selectUploadModalIsOpen = state => state[name].uploadModalOpen;
export const selectUploadProgress = state => state[name].uploadProgress;
export const selectUploadFileName = state => state[name].uploadFileName;
export const selectDownloadTranscriptsModal = state => state[name].downloadTranscriptsModal;
export const selectFiltersHamburgerIsExpanded = state => state[name].filtersHamburgerExpanded;
export const selectDeleteModalIsOpen = state => state[name].deleteModalOpen;
export const selectBulkDeleteModalIsOpen = state => state[name].bulkDeleteModalOpen;
export const selectEditModalIsOpen = state => state[name].editModalOpen;
export const selectCreateContactModalIsOpen = state => state[name].createContactModalOpen;
export const selectExistingContactModalIsOpen = state => state[name].existingContactModalOpen;
export const selectActiveCallId = state => state[name].activeCallId;
export const selectSelectedIds = state => state[name].selectedIds;
export const selectAudioPlayer = state => state[name].audioPlayer;
export const selectDropdownFilters = createSelector(selectFilters, (filters) => {
    var _a;
    return { filters: (Object.keys(filters)).filter((key) => typeof filters[key] === "boolean" && filters[key] === true), tags: (_a = filters === null || filters === void 0 ? void 0 : filters.tags) !== null && _a !== void 0 ? _a : [] };
});
