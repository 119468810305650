import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BanafoModal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { hideSummaryModal, selectSummaryModalIsOpen } from "src/features/conversation/conversationSlice";
import { useUpdateConversationSummaryMutation } from "src/features/conversation/conversationApi";
import { Button, LoadingOverlay, Textarea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useFocusTrap } from "@mantine/hooks";
import { useGetCallQuery } from "src/features/conversations/conversationApi";
const EditSummaryModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { callId: id } = useParams();
    const show = useAppSelector(selectSummaryModalIsOpen);
    const [updateSummary] = useUpdateConversationSummaryMutation({ fixedCacheKey: "update-summary" });
    const { data: conversation = {}, isLoading } = useGetCallQuery(id);
    const focusTrapRef = useFocusTrap(true);
    const form = useForm({
        initialValues: { summary: '' },
        validate: {},
    });
    useEffect(() => {
        if (isLoading || !show)
            return;
        const { summary } = conversation;
        form.initialize({ summary });
        form.setInitialValues({ summary });
        form.setValues({ summary });
    }, [isLoading, show]);
    const handleHideModal = () => {
        form.reset();
        dispatch(hideSummaryModal());
    };
    const handleSubmit = (values) => {
        updateSummary(Object.assign({ id }, values))
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t("Edit.summary_"), footer: _jsx(Button, { tt: "uppercase", type: "submit", form: "summary", color: "red", children: t("Save_") }), size: "xl", "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(LoadingOverlay, { visible: isLoading, zIndex: 1000, overlayProps: { radius: "xl", blur: 5 }, loaderProps: { color: 'teal' } }), _jsx("form", { id: "summary", onSubmit: form.onSubmit(handleSubmit), ref: focusTrapRef, children: _jsx(Textarea, Object.assign({ placeholder: t('Summary_'), autosize: true, minRows: 4 }, form.getInputProps('summary'))) })] }));
};
export default EditSummaryModal;
