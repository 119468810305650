import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BanafoModal, BifIconTrash, Button, LineSeparator } from "banafo-design-system";
import { hideBulkDeleteModal, selectBulkDeleteModalIsOpen, selectSelectedIds, } from "src/features/conversations/conversationsSlice";
import { useBulkDeleteCallsMutation } from "src/features/conversations/conversationApi";
const BulkDeleteCallsModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const show = useSelector(selectBulkDeleteModalIsOpen);
    const selectedIds = useSelector(selectSelectedIds);
    const [bulkDeleteCalls] = useBulkDeleteCallsMutation({ fixedCacheKey: "bulk-delete-recording" });
    const handleHideModal = () => {
        dispatch(hideBulkDeleteModal());
    };
    const handleBulkDeleteCalls = () => {
        bulkDeleteCalls(selectedIds)
            .unwrap()
            .catch(e => null)
            .finally(handleHideModal);
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, title: t('Delete.conversation_'), className: 'text-center', "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(LineSeparator, { color: 'dark', size: 2, className: "d-inline-block mt-100 mb-150", style: { width: 150 } }), _jsx("p", { className: 'fw-bold text-danger mb-400', children: t('Are.you.sure.you.want.to.delete.these.{{count}}.conversations.You.cannot.undo.this_', { count: selectedIds.length }) }), _jsxs("div", { className: "d-flex justify-content-center", children: [_jsx(Button, { variant: 'outline-dark', className: 'text-uppercase mx-100', onClick: handleHideModal, children: t('Cancel_') }), _jsxs(Button, { variant: 'danger', className: 'text-uppercase mx-100 with-icon end', onClick: handleBulkDeleteCalls, children: [t('Delete_'), " ", _jsx(BifIconTrash, {})] })] })] }));
};
export default BulkDeleteCallsModal;
