import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import cx from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { Image } from "banafo-design-system";
import { selectAllTranscripts, selectIsSearchViewTriggered, useGetTranscriptsQuery } from "src/features/transcripts/transcriptsSlice";
import TranscriptsChatGroupedListContainer from "src/features/transcripts/TranscriptsChatGroupedListContainer";
import TranscriptsChatListContainer from "src/features/transcripts/TranscriptsChatListContainer";
import ProcessingTranscripts from "src/features/transcripts/ProcessingTranscripts";
import invalidVoice from './images/invalidVoice.jpg';
import './TranscriptsContainer.scss';
import { Stack, Anchor, Text } from "@mantine/core";
import UpgradeButton from "src/features/sidebar/UpgradeButton";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
import { useEffect, useState } from "react";
import { selectConversationFileIds, selectConversationHasRecordings, useGetCallQuery } from "src/features/conversations/conversationApi";
const TranscriptsContainer = () => {
    const { t } = useTranslation();
    const { callId } = useParams();
    const isSearchViewTriggered = useSelector(selectIsSearchViewTriggered);
    const [shouldPollTranscripts, setShouldPollTranscripts] = useState(true);
    const { isUninitialized, isSuccess } = useGetCallQuery(callId);
    const hasRecordings = useSelector((state) => selectConversationHasRecordings(state, callId));
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const transcripts = useSelector((state) => selectAllTranscripts(state, { fileId: fileIds }));
    const { isFeatureEnabled, subscriptionPlan: { isGroupFree } } = useAccount();
    const transcriptSelectEnabled = isFeatureEnabled(FeatureTypes.TranscriptSelect);
    const { isLoading: isLoadingTranscripts } = useGetTranscriptsQuery({ fileId: fileIds }, {
        pollingInterval: shouldPollTranscripts ? 1000 : 0,
        skipPollingIfUnfocused: true,
        skip: (!isSuccess || (!isUninitialized && !hasRecordings))
    });
    useEffect(() => {
        if (transcripts.length > 0 && transcripts[0].transcript.length > 0) {
            setShouldPollTranscripts(false);
        }
    }, [transcripts]);
    if (!isUninitialized && !hasRecordings) {
        return (_jsxs(_Fragment, { children: [_jsx("div", { className: "d-flex my-200 justify-content-center", children: _jsx(Image, { fluid: true, src: invalidVoice, alt: 'no recording' }) }), _jsxs("div", { className: "text-center", children: [_jsx("h5", { className: "text-uppercase", children: _jsx(Trans, { i18nKey: "infos:Transcript.no.recording.title_" }) }), t('The.issue.might.be_'), ":", _jsxs("ul", { style: { listStylePosition: 'inside' }, children: [_jsx("li", { children: t('The.device.that.you.are.trying.to.use.with.Banafo.has.not.recorded.audio_') }), _jsx("li", { children: t('You.may.have.turned.off.auto-recording.feature.on.your.device_') })] })] })] }));
    }
    const showMissingTranscriptMessage = (stream) => {
        switch (stream[0].status) {
            case 'processing_failed':
                switch (stream[0].failureReason) {
                    case 'LimitReached':
                        return (_jsxs(_Fragment, { children: [_jsx(ProcessingTranscripts, { status: "LimitReached" }), _jsxs(Stack, { align: "center", gap: "xs", children: [t('infos:Transcript.minutes.limit.reached_'), !isGroupFree &&
                                            _jsx(Text, { children: _jsx(Trans, { i18nKey: 'infos:Contact.us.at.to.upgrade_', components: [_jsx(Anchor, { c: "teal", underline: "always", href: "mailto:yello@banafo.com", children: "yello@banafo.com" })] }) }), _jsx(UpgradeButton, {})] })] }));
                    case 'InvalidVoice':
                        return (_jsxs(_Fragment, { children: [_jsx(ProcessingTranscripts, { status: "InvalidVoice" }), _jsx("div", { className: "d-flex justify-content-center", children: _jsx(Trans, { i18nKey: "infos:Transcript.invalid.voice_" }) })] }));
                    case 'FileTooShort':
                        return (_jsxs(_Fragment, { children: [_jsx(ProcessingTranscripts, { status: "FileTooShort" }), _jsx("div", { className: "d-flex justify-content-center", children: _jsx(Trans, { i18nKey: "infos:Transcript.file.too.short_" }) })] }));
                    default:
                        return (_jsxs(_Fragment, { children: [_jsx(ProcessingTranscripts, { status: "BrokenAudio" }), _jsx("div", { className: "d-flex justify-content-center", children: t('There.was.an.error.processing.your.recording_') })] }));
                }
            default:
                return (_jsxs(_Fragment, { children: [_jsx(ProcessingTranscripts, { status: "processing" }), _jsx("div", { className: "d-flex justify-content-center", children: t('infos:Transcript.processing_') })] }));
        }
    };
    if (isLoadingTranscripts) {
        return _jsx(ProcessingTranscripts, {});
    }
    if (transcripts.length > 0) {
        const doneTranscript = transcripts.find(t => t.status === "done");
        if (doneTranscript) {
            return (_jsx("div", { className: cx("transcripts-container", { "user-select-none": !transcriptSelectEnabled }), onContextMenu: (e) => { if (!transcriptSelectEnabled) {
                    e.preventDefault();
                } }, children: isSearchViewTriggered ? _jsx(TranscriptsChatGroupedListContainer, {}) : _jsx(TranscriptsChatListContainer, {}) }));
        }
        return (_jsx(_Fragment, { children: showMissingTranscriptMessage(transcripts) }));
    }
};
export default TranscriptsContainer;
