var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import apiApp from "src/app/services/apiApp";
import { processFilters } from "src/features/conversations/utils/processFilters";
import { CONVERSATION_TYPE_PHONE_CALL, CONVERSATIONS_PAGINATION_LIMIT } from "src/features/conversations/config";
import { getUser, oidcLogout } from "src/app/services/authService";
import axios from "axios";
import fileHash from "src/common/utils/fileHash";
import i18n from "i18next";
import { createAction, createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import range from "src/common/utils/range";
import { resetUpload, setUploadFileName, setUploadProgress } from "src/features/conversations/conversationsSlice";
export const conversationsAdapter = createEntityAdapter();
export const initialState = conversationsAdapter.getInitialState({
    query: {},
    exceededLimits: undefined
});
export const conversationsReset = createAction('conversations/conversationsReset');
export const extendedApi = apiApp.injectEndpoints({
    endpoints: builder => ({
        getCalls: builder.query({
            query: ({ start, filters }) => {
                filters = processFilters(filters);
                const payload = Object.assign(Object.assign({}, filters), { start, limit: CONVERSATIONS_PAGINATION_LIMIT });
                const params = new URLSearchParams(payload);
                return `/calls?${params}`;
            },
            transformResponse: ({ list = [], query = {}, exceededLimits }) => conversationsAdapter.setAll(Object.assign(Object.assign({}, initialState), { exceededLimits, query }), list.map((_a) => {
                var { _id } = _a, item = __rest(_a, ["_id"]);
                return (Object.assign({ id: _id }, item));
            })),
            keepUnusedDataFor: 0,
            onCacheEntryAdded(arg, { cacheEntryRemoved, dispatch }) {
                return __awaiter(this, void 0, void 0, function* () {
                    const { start } = arg;
                    yield cacheEntryRemoved;
                    // if(start === 0){
                    // 	dispatch(conversationsRemoved(arg))
                    // }
                });
            },
            providesTags: (result) => result
                ?
                    [
                        ...conversationsAdapter.getSelectors().selectAll(result).map(({ id }) => ({ type: 'Calls', id })),
                        { type: 'Calls', id: 'LIST' },
                    ]
                :
                    [{ type: 'Calls', id: 'LIST' }],
        }),
        getCall: builder.query({
            query: (id) => {
                return `/calls/${id}`;
            },
            transformResponse: (_a) => {
                var { _id: id, contact, recordings = [] } = _a, call = __rest(_a, ["_id", "contact", "recordings"]);
                if (contact) {
                    const { _id: contactId } = contact, contactRest = __rest(contact, ["_id"]);
                    contact = Object.assign({ id: contactId }, contactRest);
                }
                return (Object.assign({ id,
                    contact, recordings: recordings.map((_a) => {
                        var { _id: id } = _a, recording = __rest(_a, ["_id"]);
                        return (Object.assign({ id }, recording));
                    }) }, call));
            },
            providesTags: ["Call"]
        }),
        getTags: builder.query({ query: () => {
                return '/settings/tags';
            } }),
        uploadFile: builder.mutation({
            queryFn: (_a, _b, extraOptions, baseQuery) => __awaiter(void 0, void 0, void 0, function* () {
                var { file } = _a, otherData = __rest(_a, ["file"]);
                var signal = _b.signal, dispatch = _b.dispatch, getState = _b.getState;
                const prepareHeaders = (headers) => __awaiter(void 0, void 0, void 0, function* () {
                    const user = yield getUser();
                    const { expired = true } = user || {};
                    if (expired) {
                        return oidcLogout();
                    }
                    const token = user ? user.access_token : null;
                    if (token) {
                        headers.set('Authorization', `Bearer ${token}`);
                    }
                    return headers;
                });
                let headers = new Headers({});
                headers = yield prepareHeaders(headers);
                const request = new Request('', { headers });
                const requestClone = request.clone();
                const meta = { request: requestClone };
                // rtk query supports timeout in latest versions this can be refactored
                const fetchTimeoutQuery = ({ baseUrl, xStartByte } = { baseUrl: '' }) => ({ url, method, headers: defaultHeaders, body, timeout }) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        let headers = new Headers(Object.assign({}, defaultHeaders));
                        headers = yield prepareHeaders(headers);
                        const resource = baseUrl + url;
                        const CHUNCK_SIZE = 5242880;
                        const { data: responseData } = yield axios({
                            url: resource,
                            method,
                            headers: Object.fromEntries(headers),
                            data: body,
                            timeout,
                            onUploadProgress: (progressEvent) => {
                                const uploadedChunks = Math.floor((xStartByte + progressEvent.loaded) / CHUNCK_SIZE);
                                const totalChunks = (xStartByte + progressEvent.total) / CHUNCK_SIZE; // this could be fractional if you don't `Math.ceil` it
                                const progress = progressEvent.progress === 1 ? 100 : uploadedChunks / totalChunks * 100;
                                dispatch(setUploadProgress(progress));
                            },
                        });
                        return { data: responseData };
                    }
                    catch (e) {
                        if (e.code === 'ECONNABORTED') {
                            return {
                                error: {
                                    status: 'TIMEOUT_ERROR',
                                    data: String(e),
                                },
                            };
                        }
                        return {
                            error: {
                                status: e.code,
                                data: e.response.data,
                            },
                        };
                    }
                });
                const createRecordingPlaceholder = (data) => {
                    return baseQuery({
                        url: '/media/recordings',
                        method: 'post',
                        body: data,
                    });
                };
                const resumableRecordingUpload = (fileId, file, xStartByte) => {
                    const formData = new FormData();
                    formData.append('theFile', file);
                    const baseQuery = fetchTimeoutQuery({ baseUrl: '/api/v1', xStartByte });
                    return baseQuery({
                        url: `/media/${fileId}`,
                        method: 'put',
                        headers: {
                            'x-start-byte': xStartByte,
                        },
                        timeout: 180000,
                        body: formData,
                    });
                };
                const resumableFileUpload = (data, file, retries = 3, backoff = 500) => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const createRecordingPlaceholderRes = yield createRecordingPlaceholder(data);
                        if (createRecordingPlaceholderRes.error) {
                            throw new Error('createRecordingPlaceholderRes', { cause: createRecordingPlaceholderRes.error });
                        }
                        const { data: { fileId, size: xStartByte } } = createRecordingPlaceholderRes;
                        let fileSlice = null;
                        if (xStartByte > 0) {
                            fileSlice = file.slice(xStartByte);
                        }
                        else {
                            fileSlice = file;
                        }
                        const resumableRecordingUploadRes = yield resumableRecordingUpload(fileId, fileSlice, xStartByte);
                        if (resumableRecordingUploadRes.error) {
                            throw new Error('resumableRecordingUploadRes', { cause: resumableRecordingUploadRes.error });
                        }
                    }
                    catch (e) {
                        if (retries > 0 && (e.cause.status === 'TIMEOUT_ERROR')) {
                            yield new Promise(resolve => setTimeout(resolve, backoff));
                            return yield resumableFileUpload(data, file, retries - 1, backoff + backoff);
                        }
                        throw e;
                    }
                    return Promise.resolve();
                });
                try {
                    const { type, language: languageCode } = otherData, restData = __rest(otherData, ["type", "language"]);
                    const fileName = file.name;
                    dispatch(setUploadFileName(otherData.title || fileName));
                    const hash = yield fileHash(file);
                    let phoneCallProps;
                    if (type === CONVERSATION_TYPE_PHONE_CALL) {
                        phoneCallProps = { status: "finished" };
                    }
                    const data = Object.assign(Object.assign(Object.assign({}, restData), { languageCode,
                        type,
                        hash,
                        fileName }), (type === CONVERSATION_TYPE_PHONE_CALL && phoneCallProps));
                    yield resumableFileUpload(data, file);
                    return {
                        data: i18n.t('File.uploaded.successfully_'),
                        meta
                    };
                }
                catch (e) {
                    return {
                        error: e.cause || e.message,
                        meta
                    };
                }
            }),
            onQueryStarted(arg, { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }) {
                return __awaiter(this, void 0, void 0, function* () {
                    try {
                        yield queryFulfilled;
                    }
                    catch (_a) {
                    }
                    finally {
                        dispatch(resetUpload());
                    }
                });
            },
            invalidatesTags: [{ type: 'Calls', id: 'LIST' }]
        }),
        deleteCall: builder.mutation({
            query(id) {
                return {
                    url: `/calls/${id}`,
                    method: "DELETE"
                };
            },
            invalidatesTags: [{ type: 'Calls', id: 'LIST' }],
        }),
        bulkDeleteCalls: builder.mutation({
            query(ids) {
                return {
                    url: `/calls/delete`,
                    method: "POST",
                    body: { callIds: ids }
                };
            },
            invalidatesTags: [{ type: 'Calls', id: 'LIST' }],
        }),
        updateCall: builder.mutation({
            query({ id, data }) {
                return {
                    url: `/calls/${id}`,
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: ["Call", { type: 'Calls', id: 'LIST' }],
        }),
        updateCallUnmapContact: builder.mutation({
            query({ id, data }) {
                return {
                    url: `/calls/${id}`,
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: [{ type: 'Calls', id: 'LIST' }],
        }),
        updateCallMapContact: builder.mutation({
            query({ id, data }) {
                return {
                    url: `/calls/${id}`,
                    method: "PUT",
                    body: data
                };
            },
            invalidatesTags: [{ type: 'Calls', id: 'LIST' }],
        }),
    })
});
export const { useGetCallsQuery, useGetCallQuery, useGetTagsQuery, useUploadFileMutation, useDeleteCallMutation, useBulkDeleteCallsMutation, useUpdateCallMutation, useUpdateCallUnmapContactMutation, useUpdateCallMapContactMutation, } = apiApp;
//SELECTORS
export const { selectIds: selectConversationIds, selectAll: selectAllConversations, selectEntities: selectEntitiesConversations, } = conversationsAdapter.getSelectors((state, args) => { var _a; return (_a = selectConversationsData(state, args)) !== null && _a !== void 0 ? _a : initialState; });
//CONVERSATION
export const selectConversationResult = (state, args) => extendedApi.endpoints.getCall.select(args)(state);
export const selectConversationData = createSelector(selectConversationResult, conversationResult => conversationResult.data);
export const selectConversation = selectConversationData;
export const selectConversationHasRecordings = createSelector(selectConversationData, (data = { recordings: [] }) => {
    return !!data.recordings.length;
});
export const selectConversationStreams = createSelector(selectConversationData, (data = { recordings: [] }) => {
    var _a, _b;
    return (_b = (_a = data.recordings[0]) === null || _a === void 0 ? void 0 : _a.streams.map((_a) => {
        var { _id: id } = _a, stream = __rest(_a, ["_id"]);
        return (Object.assign({ id }, stream));
    })) !== null && _b !== void 0 ? _b : [];
});
export const selectConversationFileIds = createSelector(selectConversationStreams, (streams = []) => {
    return streams.map(stream => stream.fileId).join(',');
});
export const selectConversationFileIdsArray = createSelector(selectConversationStreams, (streams = []) => {
    return streams.map(stream => stream.fileId);
});
//CONVERSATIONS
export const selectConversationsResult = (state, args) => extendedApi.endpoints.getCalls.select(args)(state);
export const selectConversationsData = createSelector(selectConversationsResult, conversationsResult => conversationsResult.data);
export const selectConversationsById = createSelector((state, args) => { var _a; return (_a = selectConversationsData(state, args)) !== null && _a !== void 0 ? _a : initialState; }, (state, args, id) => id, (data, id) => (conversationsAdapter.getSelectors().selectById(data, id) || {}));
export const selectHasNextPage = createSelector(selectConversationsData, (data = initialState) => {
    const { exceededLimits, query: { total, start, limit } } = data;
    if (exceededLimits) {
        return false;
    }
    const hasNextPage = (start + limit) < total;
    return hasNextPage;
});
export const selectCombinedConversations = (state, args) => {
    const { start } = args;
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    let conversations = [];
    for (let x of range(0, start, perPage)) {
        conversations = [...conversations, ...selectAllConversations(state, Object.assign(Object.assign({}, args), { start: x }))];
    }
    return conversations;
};
export const selectCombinedConversationIds = (state, args) => {
    const { start } = args;
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    let conversationIds = [];
    for (let x of range(0, start, perPage)) {
        conversationIds = [...conversationIds, ...selectConversationIds(state, Object.assign(Object.assign({}, args), { start: x }))];
    }
    return conversationIds;
};
export const selectCombinedConversationsById = (state, args, id) => {
    const conversations = selectCombinedConversations(state, args);
    let conversation = conversations.find(conversation => conversation.id === id) || {};
    return conversation;
};
export const selectCombinedConversationsResult = (state, args) => {
    const hasNextPage = selectHasNextPage(state, args);
    const { isError, isLoading, isSuccess, isUninitialized } = selectConversationsResult(state, args);
    const conversations = selectCombinedConversations(state, args);
    return {
        data: {
            conversations,
            total: conversations.length,
            hasNextPage
        },
        isError,
        isLoading,
        isSuccess,
        isUninitialized
    };
};
