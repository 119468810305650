var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import cx from "classnames";
import AnalysesChatItem from "src/features/aiAssistant/AnalysesChatItem";
import "../transcripts/TranscriptsChatList.scss";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectAnalysesAsChat, useGetTranscriptsAnalysesQuery, useGetTranscriptsAnalysesTypesQuery } from "src/features/aiAssistant/aiAssistantApi";
import { useParams } from "react-router-dom";
import { Skeleton } from "@mantine/core";
import { selectConversationFileIds, selectConversationHasRecordings, useGetCallQuery } from "src/features/conversations/conversationApi";
const AnalysesChatList = () => {
    const containerRef = useRef(null);
    const { callId } = useParams();
    const { isUninitialized: isUninitializedCall, isSuccess: isSuccessCall } = useGetCallQuery(callId);
    const hasRecordings = useSelector((state) => selectConversationHasRecordings(state, callId));
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const analyses = useSelector((state) => selectAnalysesAsChat(state, { fileIds }));
    const { isLoading, isFetching, isSuccess } = useGetTranscriptsAnalysesQuery({ fileIds }, { skip: (!isSuccessCall || (!isUninitializedCall && !hasRecordings)) });
    useGetTranscriptsAnalysesTypesQuery();
    useEffect(() => {
        var _a;
        if (isFetching)
            return;
        (_a = containerRef.current.lastChild) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
    }, [isFetching]);
    return (_jsxs("div", { className: cx("transcripts-chat mt-150 analyses"), ref: containerRef, children: [isLoading &&
                _jsxs(_Fragment, { children: [_jsxs("div", { className: "transcripts-chat-row transcripts-chat-left", children: [_jsx("div", { className: cx("speaker"), children: _jsx(Skeleton, { height: 50, width: 50, circle: true, mb: "xl" }) }), _jsxs("div", { className: "content", children: [_jsx(Skeleton, { height: 12, mt: 20, width: "15%", radius: "lg" }), _jsx(Skeleton, { height: 70, mt: 20, width: "100%", radius: "xl", style: { borderTopLeftRadius: 0 } })] })] }), _jsxs("div", { className: "transcripts-chat-row transcripts-chat-right", children: [_jsx("div", { className: cx("speaker"), children: _jsx(Skeleton, { height: 50, width: 50, circle: true, mb: "xl" }) }), _jsxs("div", { className: "content", children: [_jsx(Skeleton, { height: 12, mt: 20, width: "15%", radius: "lg", ml: "auto" }), _jsx(Skeleton, { height: 70, mt: 20, width: "100%", radius: "xl", style: { borderTopRightRadius: 0 } })] })] })] }), analyses.map((_a, index) => {
                var { id } = _a, analysis = __rest(_a, ["id"]);
                return _jsx(AnalysesChatItem, { analysis: Object.assign({ id }, analysis), enableFaded: false }, index);
            })] }));
};
export default AnalysesChatList;
