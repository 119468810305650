var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _TranscriptGroupChatFactory_groupTranscriptStreams;
import parse from "html-react-parser";
import AbstractTranscriptChatFactory from "src/features/transcripts/utils/factory/AbstractTranscriptChatFactory";
class TranscriptGroupChatFactory extends AbstractTranscriptChatFactory {
    constructor() {
        super(...arguments);
        _TranscriptGroupChatFactory_groupTranscriptStreams.set(this, (transcriptStreams, filters) => transcriptStreams
            .map((transcript, index, array) => {
            const searchTerm = filters.search;
            const regex = new RegExp(searchTerm, "gi"); // Case-insensitive regex
            const matches = transcript.text.match(regex);
            if (!matches) {
                return;
            }
            const prev = array[index - 1] && Object.assign(Object.assign({}, array[index - 1]), { matched: false });
            const next = array[index + 1] && Object.assign(Object.assign({}, array[index + 1]), { matched: false });
            const matched = Object.assign(Object.assign({}, transcript), { text: parse(transcript.text.replace(regex, (match) => `<span class="highlighted">${match}</span>`)), matched: true });
            return [prev, matched, next].filter(Boolean);
        }).filter(Boolean));
    }
    create(transcriptStreams, filters) {
        const transcriptStreamsGroups = __classPrivateFieldGet(this, _TranscriptGroupChatFactory_groupTranscriptStreams, "f").call(this, transcriptStreams, filters);
        const transcriptGroupedChat = transcriptStreamsGroups.map(transcriptStreamsGroup => this._convertStreamsToChat(transcriptStreamsGroup));
        return transcriptGroupedChat;
    }
}
_TranscriptGroupChatFactory_groupTranscriptStreams = new WeakMap();
export default TranscriptGroupChatFactory;
