import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { isNonEmptyArray } from "src/common/utils/typeGuards";
import { Fragment } from "react";
const formatText = (items, isLink, linkPrefix) => {
    return items.length > 0 ? (items.map((item, index) => (_jsxs(Fragment, { children: [isLink && linkPrefix ? (_jsx("a", { href: `${linkPrefix}${encodeURIComponent(item)}`, target: "_blank", rel: "noopener noreferrer", children: item })) : (item), index < items.length - 1 && ", "] }, index)))) : " ";
};
const AiEntities = ({ entities }) => {
    const { t } = useTranslation();
    return (_jsx(_Fragment, { children: typeof entities === "string" ? (_jsx(Text, { children: entities })) : (_jsxs(_Fragment, { children: [isNonEmptyArray(entities === null || entities === void 0 ? void 0 : entities.names) && (_jsxs(_Fragment, { children: [_jsx(Title, { size: "h6", children: t('Names_') }), _jsx(Text, { mb: "md", children: formatText(entities.names) })] })), isNonEmptyArray(entities === null || entities === void 0 ? void 0 : entities.phone_numbers) && (_jsxs(_Fragment, { children: [_jsx(Title, { size: "h6", children: t('Contact.Numbers_') }), _jsx(Text, { mb: "md", children: formatText(entities.phone_numbers) })] })), isNonEmptyArray(entities === null || entities === void 0 ? void 0 : entities.emails) && (_jsxs(_Fragment, { children: [_jsx(Title, { size: "h6", children: t('Email.Addresses_') }), _jsx(Text, { mb: "md", children: formatText(entities.emails, true, "mailto:") })] })), isNonEmptyArray(entities === null || entities === void 0 ? void 0 : entities.addresses) && (_jsxs(_Fragment, { children: [_jsx(Title, { size: "h6", children: t('Physical.Addresses_') }), _jsx(Text, { mb: "md", children: formatText(entities.addresses, true, "https://www.google.com/maps/search/?api=1&query=") })] })), isNonEmptyArray(entities === null || entities === void 0 ? void 0 : entities.dates) && (_jsxs(_Fragment, { children: [_jsx(Title, { size: "h6", children: t('Important.Dates_') }), _jsx(Text, { mb: "md", children: formatText(entities.dates) })] })), isNonEmptyArray(entities === null || entities === void 0 ? void 0 : entities.company_names) && (_jsxs(_Fragment, { children: [_jsx(Title, { size: "h6", children: t('Business.or.Organization.Names_') }), _jsx(Text, { mb: "md", children: formatText(entities.company_names) })] }))] })) }));
};
export default AiEntities;
