import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoModal, Button, Col, Form, Row } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { hideDownloadTranscriptsModal, selectDownloadTranscriptsModal } from "src/features/conversations/conversationsSlice";
import { downloadTranscripts } from "src/features/auth/store/actions/transcripts";
import { stopAppLoader } from "src/features/auth/store/actions/app";
import { selectRecordingDetailsByCallId, selectRecordingDetailsStreamsById } from "src/features/auth/store/selectors/recordingDetails";
import { TRANSCRIPT_DOWNLOAD_FORMATS_ENUM } from "src/features/transcripts/config";
import configEnumProp from "src/common/utils/config/configEnumProp";
const DownloadTranscriptModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { callId } = useParams();
    const recording = useSelector((state) => selectRecordingDetailsByCallId(state)(callId));
    const streams = useSelector((state) => selectRecordingDetailsStreamsById(state)(recording === null || recording === void 0 ? void 0 : recording.id));
    const { isOpen: show, format } = useSelector(selectDownloadTranscriptsModal);
    const initialFormState = {
        lineTimestamps: true,
        speakers: true,
    };
    const [inputs, setInputs] = useState(initialFormState);
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setInputs(Object.assign(Object.assign({}, inputs), { [name]: checked }));
    };
    const handleHideModal = () => {
        setInputs(initialFormState);
        dispatch(hideDownloadTranscriptsModal());
    };
    const handleDownloadTranscript = () => {
        dispatch(downloadTranscripts(streams.map(stream => stream.fileId).join(','), format, inputs))
            .catch((err) => err)
            .finally(() => {
            stopAppLoader();
            handleHideModal();
        });
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, footer: _jsx(Button, { variant: "dark", onClick: handleDownloadTranscript, children: t("Download_") }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx("h4", { children: format && configEnumProp(TRANSCRIPT_DOWNLOAD_FORMATS_ENUM, format, 'text', '') }), _jsxs(Form, { children: [_jsxs(Row, { className: "justify-content-between align-items-center", children: [_jsx(Form.Label, { column: true, xs: "auto", children: t('Timestamps.per.line_') }), _jsx(Col, { xs: "auto", children: _jsx(Form.Check, { type: "switch", inline: true, reverse: true, name: "lineTimestamps", label: inputs.lineTimestamps ? t('Yes_') : t('No_'), id: "lineTimestamps", checked: inputs.lineTimestamps, onChange: handleCheckboxChange }) })] }), _jsxs(Row, { className: "justify-content-between align-items-center", children: [_jsx(Form.Label, { column: true, xs: "auto", children: t('Speaker_') }), _jsx(Col, { xs: "auto", children: _jsx(Form.Check, { type: "switch", inline: true, reverse: true, name: "speakers", label: inputs.speakers ? t('Yes_') : t('No_'), id: "speaker", checked: inputs.speakers, onChange: handleCheckboxChange }) })] })] })] }));
};
export default DownloadTranscriptModal;
