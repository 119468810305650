import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BanafoModal } from "banafo-design-system";
import { Button, NumberInput, Text, Title } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { hideSpeakerRecognitionSpeakersNumberModal, selectSpeakerRecognitionSpeakersNumberModalIsOpen, useAddNumberOfSpeakersMutation } from "src/features/speakerRecognition/speakerRecognitionSlice";
import { useParams } from "react-router-dom";
import { isInRange, useForm } from "@mantine/form";
import { selectConversationFileIdsArray } from "src/features/conversations/conversationApi";
const SpeakerRecognitionSpeakersNumberModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { callId } = useParams();
    // @ts-ignore
    const fileIds = useAppSelector((state) => selectConversationFileIdsArray(state, callId));
    const show = useAppSelector(selectSpeakerRecognitionSpeakersNumberModalIsOpen);
    const [addNumberOfSpeakers] = useAddNumberOfSpeakersMutation();
    const form = useForm({
        initialValues: {
            numberOfSpeakers: undefined,
        },
        validate: {
            numberOfSpeakers: isInRange({ min: 1, max: 10 }, t('Speakers.must.be.between.1.and.10_'))
        },
    });
    const handleHideModal = () => {
        dispatch(hideSpeakerRecognitionSpeakersNumberModal());
    };
    const handleConfirmClick = () => {
        const { numberOfSpeakers } = form.values;
        const { hasErrors } = form.validate();
        if (!hasErrors) {
            addNumberOfSpeakers({ numberOfSpeakers: numberOfSpeakers, fileIds });
            dispatch(hideSpeakerRecognitionSpeakersNumberModal());
            form.reset();
        }
    };
    return (_jsxs(BanafoModal, { show: show, onHide: handleHideModal, footer: _jsx(Button, { color: "teal", tt: "uppercase", onClick: handleConfirmClick, children: t('Confirm_') }), "aria-labelledby": "contained-modal-title-vcenter", children: [_jsx(Title, { order: 3, mb: "sm", children: t('Add.speakers_') }), _jsxs(Title, { order: 4, size: "h4", mb: "sm", c: 'red', children: [t('Important.information_'), ":"] }), _jsx(Text, { mb: "xs", children: _jsx(Trans, { i18nKey: "infos:Speakers.number_" }) }), _jsx(NumberInput, Object.assign({ label: t('Number.of.speakers_'), withAsterisk: true, description: t('max.10.allowed_'), placeholder: t('Speakers.number_'), hideControls: true, allowNegative: false, allowDecimal: false, inputWrapperOrder: ['label', 'input', 'description', 'error'] }, form.getInputProps('numberOfSpeakers')))] }));
};
export default SpeakerRecognitionSpeakersNumberModal;
