var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { BifIconCopy, BifIconInformationCircle, BifIconKey, Col, Form, Row } from "banafo-design-system";
import { Button, ComboboxChevron, Divider, Group, Loader, Select, Text, TextInput } from "@mantine/core";
import useConvertToCombobox from "src/common/hooks/useConvertToCombobox";
import { isNotEmpty, useField } from "@mantine/form";
import React, { useEffect, useRef, useState } from "react";
import ApiKeysTable from "src/features/apiKeys/ApiKeysTable";
import { useAppSelector } from "src/app/store/hooks";
import ApiKeysRow from "src/features/apiKeys/ApiKeysRow";
import DeleteApiKeyModal from "src/features/apiKeys/modals/DeleteApiKeyModal";
import { VoIpServerTypes, VoIpServerTypesTranslationKeys } from "src/features/pbx/types";
import { selectActiveApiKeys, useGenerateApiKeyMutation, useGenerateFtpPassMutation, useGetApiKeysQuery, useGetApiKeysSettingsQuery, useRevokeApiKeyMutation, useUpdateApiKeysSettingsMutation } from "src/features/apiKeys/apiKeysSlice";
import GuideLink from "src/features/pbx/GuideLink";
function isCustomPbxType(value) {
    const { Other } = VoIpServerTypes, VoIpServerTypesWithoutOther = __rest(VoIpServerTypes, ["Other"]);
    return value !== null && !Object.values(VoIpServerTypesWithoutOther).includes(value);
}
export function isSelectedPbxTypeExcluding3CX(value) {
    return value !== VoIpServerTypes['3CX'] && value !== null;
}
export function isPredefinedPbxTypeExcluding3CX(value) {
    return !isCustomPbxType(value) && value !== VoIpServerTypes['3CX'] && value !== null;
}
const SettingsForm = () => {
    const { t } = useTranslation();
    const voIpServers = useConvertToCombobox(VoIpServerTypesTranslationKeys);
    const { data: { ftpConfig, selectedPbx } = { ftpConfig: {}, selectedPbx: null }, isLoading: isLoadingFtpSettings } = useGetApiKeysSettingsQuery();
    const [updateSettings, { isLoading: isUpdating }] = useUpdateApiKeysSettingsMutation();
    const [generateFtpPass, { data: dataFtpPass, isLoading: isLoadingGeneratePass, isSuccess: isSuccessGeneratePass, reset: resetFtpPass }] = useGenerateFtpPassMutation();
    // Check if selectedPbx is not null and not in VoIpServerTypes
    const isCustomPbx = isCustomPbxType(selectedPbx);
    // State for other PBX
    const pbxField = useField({
        initialValue: '',
        validate: isNotEmpty(t('PBX.system.is.required_')),
    });
    // Update state when selectedPbx is available
    useEffect(() => {
        if (!isLoadingFtpSettings && isCustomPbx) {
            pbxField.setValue(selectedPbx);
        }
    }, [isLoadingFtpSettings, selectedPbx, isCustomPbx]);
    const field = useField({
        initialValue: !isCustomPbx ? selectedPbx : VoIpServerTypes.Other,
        onValueChange: (value) => {
            resetFtpPass();
            resetApiKey();
            pbxField.reset();
        },
    });
    // Update field value when the query finishes
    useEffect(() => {
        if (!isLoadingFtpSettings) {
            field.setValue(!isCustomPbx ? selectedPbx : VoIpServerTypes.Other);
        }
    }, [isLoadingFtpSettings, isCustomPbx, selectedPbx]);
    const handleCreateFtpPassword = () => {
        generateFtpPass();
        updateSettings({ selectedPbx: field.getValue() });
    };
    const [copyInfoText, setCopyInfoText] = useState(t('infos:Copy.FTP.password.to.clipboard_'));
    const ftpPassRef = useRef(null);
    const handleCopyFtpPassword = () => {
        var _a, _b;
        try {
            (_a = ftpPassRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = ftpPassRef.current) === null || _b === void 0 ? void 0 : _b.select();
            document.execCommand('copy');
            setCopyInfoText(t('Copied.to.clipboard_'));
        }
        catch (err) {
            // @ts-ignore
            setCopyInfoText(err.message);
        }
    };
    // OTHER
    // @ts-ignore
    const { isLoading: isLoadingApiKeys } = useGetApiKeysQuery(undefined, { skip: !isSelectedPbxTypeExcluding3CX(field.getValue()) });
    const apiKeys = useAppSelector(selectActiveApiKeys);
    const [generateApiKey, { data: dataApiKey, isLoading: isLoadingGenerateApiKey, isSuccess: isSuccessApiKey, reset: resetApiKey }] = useGenerateApiKeyMutation();
    const handleCreateApiKey = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isCustomPbxType(field.getValue())) {
            if (yield pbxField.validate()) {
                return;
            }
        }
        generateApiKey();
        updateSettings({ selectedPbx: isCustomPbxType(field.getValue()) ? pbxField.getValue() : field.getValue() });
    });
    const [revokeApiKey] = useRevokeApiKeyMutation();
    const [deleteApiKeyModalShow, setDeleteApiKeyModalShow] = useState(false);
    const [deleteApiKeyId, setDeleteApiKeyId] = useState('');
    const handleDeleteApiKey = (id) => () => {
        setDeleteApiKeyModalShow(true);
        setDeleteApiKeyId(id);
    };
    const [copyApiKeyRefInfoText, setCopyApiKeyInfoText] = useState(t('infos:Copy.API.key.to.clipboard_'));
    const apiKeyRef = useRef(null);
    const handleCopyApiKey = () => {
        var _a, _b;
        try {
            (_a = apiKeyRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            (_b = apiKeyRef.current) === null || _b === void 0 ? void 0 : _b.select();
            document.execCommand('copy');
            setCopyApiKeyInfoText(t('Copied.to.clipboard_'));
        }
        catch (err) {
            // @ts-ignore
            setCopyApiKeyInfoText(err.message);
        }
    };
    useEffect(() => {
        resetFtpPass();
        resetApiKey();
    }, [field.getValue()]);
    return (_jsx(Form, { className: "mx-50 my-200", children: _jsxs(Form.Group, { children: [_jsx("h5", { className: "mb-150 text-uppercase", children: t('Configure.Client.Options_') }), _jsx(Row, { className: "justify-content-between align-items-center", children: _jsx(Col, { xs: "auto", children: _jsx(Select, Object.assign({ disabled: isLoadingFtpSettings, allowDeselect: false, rightSection: isLoadingFtpSettings ? _jsx(Loader, { size: 18 }) : _jsx(ComboboxChevron, { c: "dark.6" }), comboboxProps: {
                                zIndex: 1300
                            }, placeholder: t('Select.VoIP.Server_'), data: voIpServers }, field.getInputProps())) }) }), field.getValue() === VoIpServerTypes["3CX"] && !isLoadingFtpSettings &&
                    _jsxs(_Fragment, { children: [_jsx(GuideLink, { serverType: field.getValue() }), _jsxs(Row, { className: "justify-content-between align-items-center mt-150", children: [_jsxs(Form.Label, { className: "d-block", children: ["FTP server: ", ftpConfig === null || ftpConfig === void 0 ? void 0 : ftpConfig.server] }), _jsxs(Form.Label, { className: "d-block", children: ["FTP username: ", ftpConfig === null || ftpConfig === void 0 ? void 0 : ftpConfig.username] }), _jsxs(Form.Label, { className: "d-block", children: ["FTP password:", _jsx(Button, { rightSection: _jsx(BifIconKey, {}), onClick: handleCreateFtpPassword, loading: isLoadingGeneratePass, tt: "uppercase", ml: "sm", children: t('Generate.new_') })] }), _jsx(Form.Label, { className: "d-block", children: _jsx(Text, { fw: "bold", children: t('infos:Lost.your.FTP.password_') }) })] }), isSuccessGeneratePass &&
                                _jsxs(_Fragment, { children: [_jsx(Divider, { size: "md", my: "sm" }), _jsxs(Row, { className: "justify-content-center", children: [_jsx("p", { className: 'fw-bold text-danger mb-200', children: t('infos:Your.generated.FTP.password_') }), _jsxs(Col, { className: "d-flex align-items-start", children: [_jsx(BifIconInformationCircle, { className: "mt-75 text-primary" }), _jsxs(Form.Group, { className: "mx-75 flex-fill text-start", children: [_jsx(Form.Control, { readOnly: true, ref: ftpPassRef, value: dataFtpPass === null || dataFtpPass === void 0 ? void 0 : dataFtpPass.password, className: "d-inline-block" }), _jsxs(Form.Text, { className: "d-block text-dark", children: ["* ", copyInfoText] })] }), _jsx(Button, { rightSection: _jsx(BifIconCopy, {}), onClick: handleCopyFtpPassword, tt: "uppercase", children: t('Copy_') })] })] })] })] }), (isSelectedPbxTypeExcluding3CX(field.getValue())) && !isLoadingApiKeys &&
                    _jsxs(_Fragment, { children: [isCustomPbxType(field.getValue()) &&
                                _jsx(Group, { mt: "xs", children: _jsx(TextInput, Object.assign({ withAsterisk: true, label: t('Which.PBX.system.do.you.use_'), placeholder: t('Name.of.your.PBX_'), styles: { input: { paddingRight: 40 } } }, pbxField.getInputProps())) }), _jsx(GuideLink, { serverType: field.getValue() }), _jsxs(Row, { className: "my-150 g-0", children: [_jsx("h5", { className: "text-uppercase", children: t('Api.keys_') }), _jsx(Col, { className: "d-flex justify-content-between align-items-start", children: _jsx(Button, { rightSection: _jsx(BifIconKey, {}), onClick: handleCreateApiKey, loading: isLoadingGenerateApiKey, tt: "uppercase", ml: "auto", children: t('Generate.new_') }) })] }), _jsx(ApiKeysTable, { apiKeys: apiKeys, 
                                // @ts-ignore
                                renderItem: (_a) => {
                                    var { id } = _a, apiKey = __rest(_a, ["id"]);
                                    return _jsx(ApiKeysRow, Object.assign({ id: id }, apiKey, { handleDelete: handleDeleteApiKey(id) }), id);
                                } }), _jsx(DeleteApiKeyModal, { show: deleteApiKeyModalShow, onHide: () => setDeleteApiKeyModalShow(false), deleteApiKey: () => revokeApiKey(deleteApiKeyId).unwrap() }), _jsxs("div", { children: ["* ", t('infos:Api.keys.page.info.bullets.second_')] }), isSuccessApiKey &&
                                _jsxs(_Fragment, { children: [_jsx(Divider, { size: "md", my: "sm" }), _jsxs(Row, { className: "justify-content-center", children: [_jsx("p", { className: 'fw-bold text-danger mb-200', children: t('infos:Your.generated.API.key_') }), _jsxs(Col, { className: "d-flex align-items-start", children: [_jsx(BifIconInformationCircle, { className: "mt-75 text-primary" }), _jsxs(Form.Group, { className: "mx-75 flex-fill text-start", children: [_jsx(Form.Control, { readOnly: true, ref: apiKeyRef, value: dataApiKey === null || dataApiKey === void 0 ? void 0 : dataApiKey.apiKey, className: "d-inline-block" }), _jsxs(Form.Text, { className: "d-block text-dark", children: ["* ", copyApiKeyRefInfoText] })] }), _jsx(Button, { rightSection: _jsx(BifIconCopy, {}), onClick: handleCopyApiKey, tt: "uppercase", children: t('Copy_') })] })] })] })] })] }) }));
};
export default SettingsForm;
