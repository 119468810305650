import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Anchor, Box, Collapse, List, Progress, Text, ThemeIcon, Title } from "@mantine/core";
import { TbCheck, TbX } from "react-icons/tb";
import { useDisclosure } from "@mantine/hooks";
import { seekAudio } from "src/features/auth/store/actions/playback";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import classes from './AiSummary.module.css';
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
import { SETTINGS_ROUTE } from "src/app/router/config";
import { Link } from "react-router-dom";
const AiSummary = ({ callAnalysis }) => {
    const { t } = useTranslation();
    const [opened, { toggle }] = useDisclosure(false);
    const dispatch = useAppDispatch();
    const { duration: playbackDuration, ready: playbackReady, error: playbackError } = useAppSelector(state => state.app.playback);
    const { processed, completed, total, overall } = useMemo(() => {
        let completedCount = 0;
        const processed = callAnalysis.map((item) => {
            const isCompleted = item.result === "true";
            if (isCompleted)
                completedCount++;
            return Object.assign(Object.assign({}, item), { result: isCompleted, timestamp: Number(item.timestamp) });
        });
        const totalCount = processed.length;
        const overallScore = totalCount > 0 ? (completedCount / totalCount) * 100 : 0;
        return { completed: completedCount, total: totalCount, overall: Number(overallScore.toFixed(2)), processed };
    }, [callAnalysis]);
    const handleSeek = useCallback((timestamp) => (e) => {
        e.stopPropagation();
        if (playbackError !== FILE_AUTO_DELETED_ERROR) {
            if (!playbackReady)
                return;
            const duration = playbackDuration * 1000;
            const position = timestamp / duration > 1 ? 1 : timestamp / duration;
            dispatch(seekAudio(position));
        }
    }, [playbackDuration, playbackReady, playbackError, dispatch]);
    return (_jsxs(Box, { mb: "md", onClick: toggle, children: [_jsx(Title, { size: "h6", children: t('Compliance.Summary_') }), _jsx(Collapse, { in: opened, children: _jsx(List, { my: "xs", size: "sm", spacing: 0, center: true, icon: _jsx(ThemeIcon, { variant: "transparent", color: "red", size: 20, children: _jsx(TbX, { size: 16 }) }), children: processed.map(({ name, timestamp, result, step }, index) => _jsx(List.Item, { icon: _jsx(ThemeIcon, { variant: "transparent", color: result ? "teal" : "red", size: 20, children: result ? _jsx(TbCheck, { size: 16 }) : _jsx(TbX, { size: 16 }) }), classNames: {
                            item: classes.listItem,
                            itemIcon: classes.listItemIcon,
                        }, children: result ?
                            _jsx(Text, { span: true, inherit: true, onClick: handleSeek(timestamp + 1), className: classes.text, children: name })
                            : name }, index)) }) }), _jsxs(Text, { fz: "sm", children: ["Tasks completed:", ' ', _jsx(Text, { span: true, fw: 500, children: `${completed}/${total}` })] }), _jsx(Progress, { size: "sm", color: "teal", value: overall, mt: 5 }), _jsx(Text, { mt: "sm", children: _jsx(Trans, { i18nKey: 'infos:Compliance.steps.settings_', components: [
                        _jsx(Anchor, { component: Link, c: "teal", underline: "always", to: SETTINGS_ROUTE, onClick: (e) => e.stopPropagation(), children: "here" }, 0)
                    ] }) })] }));
};
export default AiSummary;
