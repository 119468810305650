import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Container, Row, SpinnerBackdrop } from "banafo-design-system";
import { selectRecordingDetailsByCallId } from "src/features/auth/store/selectors/recordingDetails";
import { selectPlaybackAudioPlaying, selectPlaybackCurrentTime } from "src/features/auth/store/selectors/playback";
import NotesControls from "./NotesControls";
import { selectAllNotes, useGetNotesQuery } from "src/features/notes/notesSlice";
import NotesTable from "src/features/notes/NotesTable";
import { MODE_VIEW, NOTE_TYPE_TIME } from "src/features/notes/config";
import EmptyNotes from "src/features/notes/EmptyNotes";
import HighlightsGallery from "src/features/notes/HighlightsGallery";
import { useGetCallQuery } from "src/features/conversations/conversationApi";
const Notes = ({ playbackCurrentTime, playbackDuration, playbackPlaying, activityNoteId }) => {
    const { callId } = useParams();
    const [mode, setMode] = useState(MODE_VIEW);
    const [descriptionInput, setDescriptionInput] = useState('');
    const [noteTypeInput, setNoteTypeInput] = useState(NOTE_TYPE_TIME);
    const [noteId, setNoteId] = useState(null);
    const [errors, setErrors] = useState({});
    const [highlightNote, setHighlightNote] = useState(activityNoteId);
    const [savedActiveNote, setSavedActiveNote] = useState(undefined);
    const [savedSyncWithPlaythrough, setSavedSyncWithPlaythrough] = useState(true);
    const recording = useSelector((state) => selectRecordingDetailsByCallId(state)(callId));
    const { isSuccess } = useGetCallQuery(callId);
    const { isLoading: isLoadingNotes } = useGetNotesQuery(callId);
    const notes = useSelector(state => selectAllNotes(state, callId));
    useEffect(() => {
        if (recording) {
            setSavedSyncWithPlaythrough(true);
        }
    }, [recording]);
    useEffect(() => {
        if (recording && savedSyncWithPlaythrough) {
            const { startedAt: recordingStart, endedAt: recordingEnd, duration: recordingDuration } = recording;
            const currentTime = playbackCurrentTime * 1000;
            const duration = playbackDuration * 1000;
            let activeNote = null;
            if (highlightNote) {
                activeNote = notes.find(item => item.id === highlightNote);
            }
            else {
                activeNote = notes.filter((note) => {
                    const notePosition = (note.cuePoint - recordingStart) / recordingDuration;
                    const audioPosition = currentTime / duration;
                    // @todo check cuePoint calculations
                    //const audioPosition = playbackCurrentTime * 1000 / playbackDuration * 1000;
                    // Ratios are compared to handle cases where the call recording duration does not match the audio duration
                    // Equality / seeking to a specific note does not work, hopefully adding EPSILON works in all cases...
                    return notePosition <= audioPosition + Number.EPSILON;
                }).pop();
            }
            setSavedActiveNote(activeNote);
        }
    }, [notes, recording, savedSyncWithPlaythrough, playbackCurrentTime, playbackDuration, playbackPlaying]);
    return (_jsxs(Container, { children: [_jsx(Row, { className: "my-50", children: _jsx(Col, { xs: 12, children: _jsx(NotesControls, { mode: mode, setMode: setMode, activeNote: savedActiveNote, setSavedActiveNote: setSavedActiveNote, setSavedSyncWithPlaythrough: setSavedSyncWithPlaythrough, savedSyncWithPlaythrough: savedSyncWithPlaythrough, descriptionInput: descriptionInput, setDescriptionInput: setDescriptionInput, noteTypeInput: noteTypeInput, setNoteTypeInput: setNoteTypeInput, noteId: noteId, errors: errors, setErrors: setErrors }) }) }), _jsx(Row, { children: _jsxs(Col, { xs: 12, children: [_jsx(HighlightsGallery, {}), (!isSuccess || isLoadingNotes) && _jsx(SpinnerBackdrop, { position: 'absolute' }), (!notes.length && mode === MODE_VIEW) && _jsx(EmptyNotes, {}), !!notes.length &&
                            _jsx(NotesTable, { notes: notes, callId: callId, mode: mode, setMode: setMode, activeNote: savedActiveNote, setSavedActiveNote: setSavedActiveNote, setSavedSyncWithPlaythrough: setSavedSyncWithPlaythrough, descriptionInput: descriptionInput, setDescriptionInput: setDescriptionInput, noteTypeInput: noteTypeInput, setNoteTypeInput: setNoteTypeInput, setNoteId: setNoteId, setErrors: setErrors })] }) })] }));
};
function mapStateToProps(state, ownProps) {
    return {
        playbackCurrentTime: selectPlaybackCurrentTime(state),
        playbackDuration: state.app.playback.duration,
        playbackPlaying: selectPlaybackAudioPlaying(state)
    };
}
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Notes);
Notes.propTypes = {
    playbackCurrentTime: PropTypes.any,
    playbackDuration: PropTypes.any,
    recording: PropTypes.any,
    playbackPlaying: PropTypes.bool,
    activityNoteId: PropTypes.any,
};
