import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Nav, OverlayTrigger, Tooltip } from "banafo-design-system";
import { AiOutlineSearch } from "react-icons/ai";
import { selectAllTranscripts, selectTranscriptLanguageCode, showChangeTranscriptLanguageModal, showSearch, useGetTranscriptLanguagesQuery } from "src/features/transcripts/transcriptsSlice";
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
import GoogleDriveTab from "src/features/googleDrive/GoogleDriveTab";
import SpeakerRecognition from "src/features/speakerRecognition/SpeakerRecognition";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const TranscriptsExtraTab = () => {
    var _a;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { callId } = useParams();
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const transcriptLanguage = useSelector((state) => selectTranscriptLanguageCode(state, { fileId: fileIds }));
    const transcripts = useSelector((state) => selectAllTranscripts(state, { fileId: fileIds }));
    const { error: playbackError } = useSelector(state => state.app.playback);
    const { data: languages = [], isLoading: isLoadingLanguages } = useGetTranscriptLanguagesQuery();
    const handleSearch = () => {
        dispatch(showSearch());
    };
    const handleTranscriptLanguageChange = (e) => {
        const languageCode = e.target.value;
        dispatch(showChangeTranscriptLanguageModal(languageCode));
    };
    return (_jsx(Nav.Item, { className: "ms-auto", children: _jsxs(Nav.Link, { className: "d-flex gap-50", children: [_jsx(SpeakerRecognition, {}), _jsx(GoogleDriveTab, {}), _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "new-point-of-interest", children: t("Search_") }), children: _jsx("span", { children: _jsx(AiOutlineSearch, { size: 20, onClick: handleSearch }) }) }), _jsx(Form.Select, { className: "w-auto d-inline-block ms-50 py-0", "aria-label": "transcript language", value: transcriptLanguage, onChange: handleTranscriptLanguageChange, disabled: !((_a = transcripts[0]) === null || _a === void 0 ? void 0 : _a.transcript.length) || playbackError === FILE_AUTO_DELETED_ERROR, children: languages.map(({ code, name }, index) => _jsx("option", { value: code, children: name }, index)) })] }) }));
};
export default TranscriptsExtraTab;
