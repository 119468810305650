import { useMemo } from "react";
import { useTranslation } from "react-i18next";
function isRecordString(data) {
    return typeof data === "object" && data !== null && !Array.isArray(data);
}
/**
 * Converts data for select
 *
 * @param data
 * @param skipTranslation for backward compatibility
 */
const useConvertToCombobox = (data, skipTranslation = false) => {
    const { t } = useTranslation();
    return useMemo(() => {
        if (Array.isArray(data)) {
            return data.map((item) => ({ label: t(`${item}_`), value: item }));
        }
        if (isRecordString(data)) {
            return Object.entries(data).map(([key, value]) => ({ label: skipTranslation ? value : t(`${value}`), value: key }));
        }
        return [];
    }, [data]);
};
export default useConvertToCombobox;
