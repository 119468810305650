import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAllTranscripts } from "src/features/transcripts/transcriptsSlice";
import GoogleDriveOpenButton from "src/features/googleDrive/GoogleDriveOpenButton";
import GoogleDriveUploadButton from "src/features/googleDrive/GoogleDriveUploadButton";
import GoogleDriveAccountUpgradeModal from "src/features/accountUpgrade/modals/GoogleDriveAccountUpgradeModal";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const GoogleDriveTab = () => {
    var _a, _b, _c;
    const { callId } = useParams();
    // @ts-ignore
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    // @ts-ignore
    const transcripts = useSelector((state) => selectAllTranscripts(state, { fileId: fileIds }));
    if (!((_a = transcripts[0]) === null || _a === void 0 ? void 0 : _a.transcript.length)) {
        return;
    }
    return (_jsxs(_Fragment, { children: [((_b = transcripts[0]) === null || _b === void 0 ? void 0 : _b.googleDriveFileId) && ((_c = transcripts[0]) === null || _c === void 0 ? void 0 : _c.googleDriveWebLink) ? _jsx(GoogleDriveOpenButton, { googleDriveUrl: transcripts[0].googleDriveWebLink }) : _jsx(GoogleDriveUploadButton, {}), _jsx(GoogleDriveAccountUpgradeModal, {})] }));
};
export default GoogleDriveTab;
