import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import classNames from "classnames";
import { SpinnerBackdrop } from "banafo-design-system";
import getConfigEnumText from "src/common/utils/config/configEnumText";
import { CONVERSATION_DIRECTIONS_ENUM, CONVERSATION_TYPE_PHONE_CALL, CONVERSATION_TYPES_ENUM } from "src/features/conversations/config";
import DetailsData from "src/features/conversation/DetailsData";
import DetailsDataContact from "src/features/conversation/DetailsDataContact";
import { formattedDuration } from "src/features/auth/util/dateTime";
import { Anchor, Text, Title } from "@mantine/core";
import ActionPointsContent from "src/features/conversation/ActionPointsContent";
import ActionPointsTitle from "src/features/conversation/ActionPointsTitle";
import SummaryContent from "src/features/conversation/SummaryContent";
import SummaryTitle from "src/features/conversation/SummaryTitle";
import "./DetailsSection.scss";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
import AiEntities from "src/features/conversation/AiEntities";
import { useGetCallQuery } from "src/features/conversations/conversationApi";
import AiSummary from "src/features/conversation/AiSummary";
const DetailsSection = ({ className }) => {
    const { callId } = useParams();
    const { t } = useTranslation();
    const { upgradeAccount } = useAccount();
    const { data: { 
    // @ts-ignore
    startedAt, duration, source, type, summary, actionPoints, remotePhone, direction, tag, entities, callAnalysis, contact } = {}, isLoading } = useGetCallQuery(callId);
    const { isFeatureEnabled } = useAccount();
    const aiAnalysisEnabled = isFeatureEnabled(FeatureTypes.AiAnalysis);
    const handlePlanUpgrade = (e) => {
        e.preventDefault();
        upgradeAccount();
    };
    const summaryText = summary || aiAnalysisEnabled ? summary : _jsx(Trans, { i18nKey: "infos:ai.feature_", components: [_jsx("a", { href: "#", onClick: handlePlanUpgrade, children: "here" }, 0)] });
    const summaryRawText = summary || aiAnalysisEnabled ? summary : '';
    if (isLoading) {
        return _jsx(SpinnerBackdrop, {});
    }
    return (_jsxs("div", { className: classNames('conversation-details', className), children: [_jsx(Title, { order: 5, fw: "bold", mb: "md", tt: "uppercase", children: t('AI.Insights_') }), ((callAnalysis === null || callAnalysis === void 0 ? void 0 : callAnalysis.length) > 0) && _jsx(AiSummary, { callAnalysis: callAnalysis }), (aiAnalysisEnabled || entities) ?
                _jsx(AiEntities, { entities: entities }) :
                _jsx(Text, { mb: "md", c: 'dark.2', children: _jsx(Trans, { i18nKey: 'infos:Ai.entities.extraction_', components: [_jsx(Anchor, { c: "teal", underline: "always", href: "#", onClick: handlePlanUpgrade, children: "here" }, 0)] }) }), aiAnalysisEnabled &&
                _jsx(DetailsData, { title: t('Tag_'), text: tag }), _jsx(SummaryTitle, { text: summaryRawText }), _jsx(SummaryContent, { text: summaryText, aiAnalysisEnabled: aiAnalysisEnabled, disableLineClamp: !summaryRawText }), aiAnalysisEnabled &&
                _jsxs(_Fragment, { children: [_jsx(ActionPointsTitle, { text: actionPoints }), _jsx(ActionPointsContent, { text: actionPoints, disableLineClamp: !summaryRawText })] }), _jsx(Title, { order: 5, fw: "bold", mb: "md", tt: "uppercase", children: t('Record.details_') }), _jsx(DetailsData, { title: t('Conversation.type_'), text: getConfigEnumText(CONVERSATION_TYPES_ENUM, type) }), _jsx(DetailsData, { title: t('Started.At_'), text: startedAt ? moment(startedAt).format('YYYY-MM-DD HH:mm:ss') : '-' }), _jsx(DetailsData, { title: t('Duration_'), text: duration ? formattedDuration(duration, 'HH:mm:ss') : '-' }), _jsx(DetailsData, { title: t('Source_'), text: source }), type === CONVERSATION_TYPE_PHONE_CALL && _jsxs(_Fragment, { children: [_jsx(DetailsData, { title: t('Phone_'), text: remotePhone }), _jsx(DetailsData, { title: t('Direction_'), text: getConfigEnumText(CONVERSATION_DIRECTIONS_ENUM, direction) })] }), contact && _jsx(DetailsDataContact, { title: t('Contact_'), contact: contact })] }));
};
export default DetailsSection;
