var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { TextInput, Textarea, Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useGetApiKeysSettingsQuery, useUpdateApiKeysSettingsMutation } from "../apiKeysSlice";
import { BanafoModal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
const ComplianceModal = ({ index, opened, onClose }) => {
    const { t } = useTranslation();
    const { data: { transcripts } = { transcripts: { verification: [] } }, isLoading } = useGetApiKeysSettingsQuery();
    const [updateSettings, { isLoading: isUpdating }] = useUpdateApiKeysSettingsMutation({ fixedCacheKey: "compliances" });
    // Form initialization
    const form = useForm({
        initialValues: {
            name: "",
            definition: "",
        },
        validate: {
            name: (value) => (value.trim().length > 0 ? null : "Name is required"),
            definition: (value) => (value.trim().length > 0 ? null : "Definition is required"),
        },
    });
    useEffect(() => {
        if (index !== undefined && transcripts.verification[index]) {
            form.setValues({
                name: transcripts.verification[index].name,
                definition: transcripts.verification[index].definition,
            });
        }
    }, [index, transcripts.verification]);
    const handleSubmit = (values) => __awaiter(void 0, void 0, void 0, function* () {
        const verification = [...transcripts.verification];
        if (index !== undefined) {
            verification[index] = { name: values.name, definition: values.definition };
        }
        else {
            verification.push({ name: values.name, definition: values.definition });
        }
        try {
            updateSettings({ transcripts: Object.assign(Object.assign({}, transcripts), { verification }) }).unwrap();
            handleClose();
        }
        catch (error) {
        }
    });
    const handleClose = () => {
        onClose();
        form.reset();
    };
    return (_jsx(BanafoModal, { show: opened, onHide: handleClose, title: index !== undefined ? t("Edit.compliance_") : t("Add.a.new.compliance_"), footer: _jsx(Button, { tt: "uppercase", type: "submit", loading: isUpdating, form: "compliances", children: index !== undefined ? t("Update_") : t("Create_") }), "aria-labelledby": "contained-modal-title-vcenter", children: _jsxs("form", { id: "compliances", onSubmit: form.onSubmit(handleSubmit), children: [_jsx(TextInput, Object.assign({ label: "Name", placeholder: "Enter compliance name", withAsterisk: true, mb: "md" }, form.getInputProps("name"))), _jsx(Textarea, Object.assign({ label: "Definition", placeholder: "Enter compliance definition", withAsterisk: true }, form.getInputProps("definition"), { minRows: 3 }))] }) }));
};
export default ComplianceModal;
