import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import { hideChangeTranscriptLanguageModal, selectChangeTranscriptLanguage, selectChangeTranscriptLanguageModalIsOpen, useUpdateTranscriptLanguageMutation } from "src/features/transcripts/transcriptsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const ChangeTranscriptLanguageModal = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { callId } = useParams();
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const isOpen = useSelector(selectChangeTranscriptLanguageModalIsOpen);
    const transcriptLanguageCode = useSelector(selectChangeTranscriptLanguage);
    const [updateTranscriptLanguage, { isLoading: isUpdating }] = useUpdateTranscriptLanguageMutation();
    const handleTranscriptLanguageChange = () => {
        updateTranscriptLanguage({ fileIds: fileIds.split(','), languageCode: transcriptLanguageCode });
        dispatch(hideChangeTranscriptLanguageModal());
    };
    const handleHideModal = () => {
        dispatch(hideChangeTranscriptLanguageModal());
    };
    return (_jsxs(Modal, { show: isOpen, "aria-labelledby": "contained-modal-title-vcenter", centered: true, onHide: handleHideModal, children: [_jsx(Modal.Header, { closeButton: true, className: "border-0" }), _jsxs(Modal.Body, { children: [_jsx("h4", { children: t('Change.transcript.language_') }), _jsx("p", { children: t('infos:transcripts.change.language.message_') })] }), _jsx(Modal.Footer, { className: "border-0", children: _jsx(Button, { variant: 'primary', onClick: handleTranscriptLanguageChange, className: 'text-uppercase', children: t('Change_') }) })] }));
};
export default ChangeTranscriptLanguageModal;
