import { jsx as _jsx } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectFilteredTranscriptsAsChat, selectFilters } from "src/features/transcripts/transcriptsSlice";
import TranscriptsChatGroupedList from "src/features/transcripts/TranscriptsChatGroupedList";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const TranscriptsChatGroupedListContainer = () => {
    const { callId } = useParams();
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const filters = useSelector(selectFilters);
    const transcripts = useSelector((state) => selectFilteredTranscriptsAsChat(state, { fileId: fileIds, callId }, filters));
    return _jsx(TranscriptsChatGroupedList, { groupedTranscripts: transcripts });
};
export default TranscriptsChatGroupedListContainer;
