import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout } from "banafo-design-system";
import DetailsSection from "src/features/conversation/DetailsSection";
import ConversationDetailsTabs from "src/features/conversations/ConversationDetailsTabs";
import Header from "src/pages/conversation/Header";
import useScrollToHighlightedTranscript from "src/features/transcripts/utils/useScrollToHighlightedTranscript";
import ChangeTranscriptLanguageModal from "src/features/transcripts/modals/ChangeTranscriptLanguageModal";
import EditSummaryModal from "src/features/conversation/modals/EditSummaryModal";
import EditActionPointsModal from "src/features/conversation/modals/EditActionPointsModal";
import SpeakersAccountUpgradeModal from "src/features/accountUpgrade/modals/SpeakersAccountUpgradeModal";
const ConversationPage = () => {
    useScrollToHighlightedTranscript();
    return (_jsxs(Layout, { header: _jsx(Header, {}), asideRight: _jsx(DetailsSection, {}), scrollBar: 0, options: {
            contentProps: { className: "p-200 d-flex flex-column" },
            asideRightProps: { className: "feeds-container details-page" }
        }, children: [_jsx(DetailsSection, { className: "d-lg-none full-width page-content mx-n200 mb-200 mt-n200 pt-200 px-200" }), _jsx(ConversationDetailsTabs, {}), _jsx(ChangeTranscriptLanguageModal, {}), _jsx(EditSummaryModal, {}), _jsx(EditActionPointsModal, {}), _jsx(SpeakersAccountUpgradeModal, {})] }));
};
export default ConversationPage;
