export const CallDirection = {
    DirectionInbound: "directionInbound",
    DirectionOutbound: "directionOutbound",
};
export const ConversationType = {
    TypeWebConference: "typeWebConference",
    TypeOther: "typeOther",
};
export const DropdownFilterValues = [...Object.values(CallDirection), ...Object.values(ConversationType)];
export const DropdownFilterKeys = {
    [CallDirection.DirectionInbound]: 'Incoming_',
    [CallDirection.DirectionOutbound]: 'Outgoing_',
    [ConversationType.TypeWebConference]: 'Web.conference_',
    [ConversationType.TypeOther]: 'Other_',
};
