import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { BanafoModal } from "banafo-design-system";
import { Button, ComboboxChevron, Select, Text, TextInput } from "@mantine/core";
import { hidePurchaseModal, selectPurchaseModalIsOpen, selectPurchaseModalResellerCustomerId, selectResellerCustomersApiArgs, switchPurchaseModals } from "src/features/resellerCustomers/resellerCustomersSlice";
import { useFocusTrap } from "@mantine/hooks";
import { selectResellerCustomerEntity } from "src/features/resellerCustomers/resellerCustomersApi";
import { usePurchaseFormContext } from "src/features/resellerCustomers/helpers/purchaseFormContext";
import { selectComboboxBillingCountries, useGetPaymentCountriesQuery } from "src/features/payments/billingApi";
import { useLazyGetCurrentPlanQuery, useLazyGetVatNumberQuery } from "src/features/payments/paymentsSlice";
import { RESELLER_SUBSCRIPTION_PLAN_NAMES } from "src/features/resellerCustomers/constants";
import useConvertToCombobox from "src/common/hooks/useConvertToCombobox";
import { useEffect } from "react";
const PurchaseModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    useGetPaymentCountriesQuery();
    const [getVatNumber, { data: vatNumberData, isSuccess, isFetching }] = useLazyGetVatNumberQuery();
    const [getPlanDetails, { isSuccess: isSuccessPlanDetails, isFetching: isFetchingPlanDetails }] = useLazyGetCurrentPlanQuery();
    const show = useAppSelector(selectPurchaseModalIsOpen);
    const comboboxCountries = useAppSelector(selectComboboxBillingCountries);
    const comboboxPlans = useConvertToCombobox(RESELLER_SUBSCRIPTION_PLAN_NAMES, true);
    const args = useAppSelector(selectResellerCustomersApiArgs);
    const resellerCustomerId = useAppSelector(selectPurchaseModalResellerCustomerId);
    const resellerCustomer = useAppSelector(state => selectResellerCustomerEntity(state, args, resellerCustomerId));
    const focusTrapRef = useFocusTrap(true);
    const form = usePurchaseFormContext();
    useEffect(() => {
        if (isFetching || isFetchingPlanDetails) {
            return;
        }
        if (!isSuccess || !isSuccessPlanDetails) {
            return;
        }
        const { companyVatNumber } = form.getValues();
        if (companyVatNumber && !vatNumberData.isValidVATNumber) {
            return form.setFieldError('companyVatNumber', t('errors:Invalid.vatNumber_'));
        }
        form.setFieldValue('vatRate', vatNumberData.standardRate);
        dispatch(switchPurchaseModals());
    }, [isSuccess, isFetching, isSuccessPlanDetails, isFetchingPlanDetails]);
    const handleHideModal = () => {
        form.reset();
        dispatch(hidePurchaseModal());
    };
    const handleSubmit = (values) => {
        getPlanDetails(form.getValues().plan);
        getVatNumber({ country: form.getValues().country, vatNumber: form.getValues().companyVatNumber });
    };
    return (_jsx(BanafoModal, { show: show, onHide: handleHideModal, title: t("Payment.details_"), footer: _jsx(Button, { tt: "uppercase", fw: "bold", type: "submit", form: "purchase", color: "dark", loading: isFetching || isFetchingPlanDetails, children: t("Checkout_") }), "aria-labelledby": "contained-modal-title-vcenter", children: _jsxs("form", { id: "purchase", onSubmit: form.onSubmit(handleSubmit), ref: focusTrapRef, children: [_jsx(TextInput, Object.assign({ label: t('Email_'), placeholder: t('Email_'), withAsterisk: true, mt: "md" }, form.getInputProps('email'))), _jsx(TextInput, Object.assign({ label: t('Full.Name_'), placeholder: t('Full.Name_'), withAsterisk: true, mt: "md" }, form.getInputProps('fullName'))), _jsx(Select, Object.assign({ allowDeselect: false, rightSection: _jsx(ComboboxChevron, { c: "dark.6" }), comboboxProps: {
                        zIndex: 1300
                    }, withAsterisk: true, label: t('Country_'), placeholder: t('Country_'), data: comboboxCountries, mt: "md" }, form.getInputProps('country'))), _jsx(TextInput, Object.assign({ withAsterisk: true, label: t('Company.VAT.number_'), placeholder: t('Company.VAT.number_'), mt: "md", leftSection: form.values.country }, form.getInputProps('companyVatNumber'))), _jsx(TextInput, Object.assign({ label: t('Company.name_'), placeholder: t('Company.name_'), withAsterisk: true, mt: "md" }, form.getInputProps('companyName'))), _jsx(TextInput, Object.assign({ label: t('Company.address_'), placeholder: t('Company.address_'), mt: "md" }, form.getInputProps('companyAddress'))), _jsx(Select, Object.assign({ allowDeselect: false, rightSection: _jsx(ComboboxChevron, { c: "dark.6" }), comboboxProps: {
                        zIndex: 1300
                    }, withAsterisk: true, label: t('Plan_'), placeholder: t('Plan_'), data: comboboxPlans, mt: "md" }, form.getInputProps('plan'))), _jsxs(Text, { size: "md", mt: "md", children: [t('Purchase.order.for.client_'), " - ", resellerCustomer === null || resellerCustomer === void 0 ? void 0 : resellerCustomer.email] })] }) }));
};
export default PurchaseModal;
