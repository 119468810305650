import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, LineSeparator, SpinnerBackdrop } from "banafo-design-system";
import { useTranslation } from "react-i18next";
import apiApp from "src/app/services/apiApp";
import range from "src/common/utils/range";
import { incrementPage, selectFilters, selectPage } from "./conversationsSlice";
import { CONVERSATIONS_PAGINATION_LIMIT } from "./config";
import CallsTable from "./CallsTable";
import TableFilters from "src/features/conversations/TableFilters";
import { conversationsReset, selectCombinedConversationsResult } from "src/features/conversations/conversationApi";
const Conversations = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const page = useSelector(selectPage);
    const searchFilters = useSelector(selectFilters);
    const perPage = CONVERSATIONS_PAGINATION_LIMIT;
    const start = page * perPage;
    const { data: { conversations, hasNextPage }, isLoading: isLoadingCalls } = useSelector((state) => selectCombinedConversationsResult(state, { start, filters: searchFilters }));
    useEffect(() => {
        const results = [];
        for (let x of range(0, start, perPage)) {
            const result = dispatch(apiApp.endpoints.getCalls.initiate({ start: x, filters: searchFilters }));
            results.push(result);
        }
        return () => {
            for (let result of results) {
                result.unsubscribe();
            }
        };
    }, [page, JSON.stringify(searchFilters)]);
    useEffect(() => {
        return () => {
            dispatch(conversationsReset());
        };
    }, []);
    const handleLoadMore = () => {
        dispatch(incrementPage());
    };
    if (isLoadingCalls) {
        // return <SpinnerBackdrop />;
    }
    return (_jsxs(_Fragment, { children: [_jsx(TableFilters, {}), _jsx(LineSeparator, { size: 3, color: "secondary" }), _jsxs("div", { className: "position-relative", children: [isLoadingCalls && _jsx(SpinnerBackdrop, { position: "absolute" }), _jsx(CallsTable, { calls: conversations }), hasNextPage &&
                        _jsx(Button, { onClick: handleLoadMore, variant: "outline-dark", className: "d-block mx-auto mt-200 text-uppercase", children: t("Load.more_") })] })] }));
};
export default Conversations;
