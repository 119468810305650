import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Group, Popover, Indicator, ActionIcon, Divider, Checkbox, Skeleton } from '@mantine/core';
import { TbFilter, } from "react-icons/tb";
import { useTranslation } from "react-i18next";
import { changeFilter, selectDropdownFilters, selectFiltersHamburgerIsExpanded, toggleFiltersHamburgerMenu } from "src/features/conversations/conversationsSlice";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import { DropdownFilterKeys, DropdownFilterValues } from "src/features/conversations/types";
import { useGetTagsQuery } from "src/features/conversations/conversationApi";
import { useSelector } from "react-redux";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
const DropdownFilters = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isHamburgerMenuExpanded = useSelector(selectFiltersHamburgerIsExpanded);
    const toggleMenu = () => dispatch(toggleFiltersHamburgerMenu());
    const { isFeatureEnabled } = useAccount();
    const aiAnalysisEnabled = isFeatureEnabled(FeatureTypes.AiAnalysis);
    const { data, isSuccess: isSuccessTags, isLoading: isLoadingTags } = useGetTagsQuery(undefined, { skip: !aiAnalysisEnabled });
    const { filters, tags } = useAppSelector(selectDropdownFilters);
    const hasActiveFilters = useMemo(() => filters.length > 0 || (aiAnalysisEnabled && tags.length > 0), [filters, aiAnalysisEnabled, tags]);
    const handleCheckboxFilterChange = (e) => {
        const { name, checked } = e.target;
        const isTag = data.list.some((item) => item.tag === name);
        dispatch(changeFilter({ name, value: checked, isTag }));
    };
    return (_jsxs(Popover, { width: 450, position: "bottom-start", shadow: "md", opened: isHamburgerMenuExpanded, onChange: toggleMenu, transitionProps: {
            transition: 'pop-top-left',
        }, children: [_jsx(Popover.Target, { children: _jsx(Indicator, { color: "red", size: 6, processing: true, position: "bottom-end", offset: 7, 
                    // withBorder
                    disabled: !hasActiveFilters, children: _jsx(ActionIcon
                    // size="sm"
                    , { 
                        // size="sm"
                        variant: "transparent", "aria-label": t("Filters_"), onClick: toggleMenu, children: _jsx(TbFilter, { size: 20 }) }) }) }), _jsxs(Popover.Dropdown, { children: [_jsxs(Checkbox.Group, { value: filters, label: t('Conversation.types_'), mb: "xs", children: [_jsx(Divider, { size: "sm", mb: "sm" }), _jsx(Group, { mt: "xs", children: DropdownFilterValues.map((filterKey) => (_jsx(Checkbox, { value: filterKey, name: filterKey, 
                                    // label={t(DropdownFilterLabels[filterKey as keyof typeof DropdownFilterLabels])} // Ensuring type safety
                                    label: t(DropdownFilterKeys[filterKey]), onChange: handleCheckboxFilterChange }, filterKey))) })] }), aiAnalysisEnabled &&
                        _jsxs(Checkbox.Group, { value: tags, label: t('Tags_'), mb: "xs", children: [_jsx(Divider, { size: "sm", mb: "sm" }), _jsx(Group, { mt: "xs", children: isLoadingTags ?
                                        _jsxs(_Fragment, { children: [_jsx(Skeleton, { height: 8, radius: "xl" }), _jsx(Skeleton, { height: 8, radius: "xl" }), _jsx(Skeleton, { height: 8, width: "70%", radius: "xl" })] }) :
                                        _jsx(_Fragment, { children: data === null || data === void 0 ? void 0 : data.list.map(({ tag }) => (_jsx(Checkbox, { value: tag, name: tag, label: tag, onChange: handleCheckboxFilterChange }, tag))) }) })] })] })] }));
};
export default DropdownFilters;
