var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { toastAdded } from "src/features/toasts/toastsSlice";
import { isAuthorizedSettledMutation } from "src/common/utils/rtk/matchers";
export const rtkQueryLogger = (store) => (next) => (action) => {
    if (isAuthorizedSettledMutation(action)) {
        const _a = action.meta, { requestId, baseQueryMeta } = _a, _b = _a.arg, { endpointName, originalArgs } = _b, argRest = __rest(_b, ["endpointName", "originalArgs"]), metaRest = __rest(_a, ["requestId", "baseQueryMeta", "arg"]), rest = __rest(action, ["meta"]);
        if (endpointName === 'updateApiKeysSettings' && (originalArgs === null || originalArgs === void 0 ? void 0 : originalArgs.hasOwnProperty('selectedPbx'))) {
            return next(action);
        }
        const newAction = Object.assign(Object.assign({}, rest), { meta: Object.assign({ requestId, arg: Object.assign({ endpointName }, argRest) }, metaRest) });
        const toast = { id: requestId, isOpen: false, action: newAction };
        store.dispatch(toastAdded(toast));
    }
    return next(action);
};
export default rtkQueryLogger;
