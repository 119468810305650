import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip } from "banafo-design-system";
import { MdOutlineAddToDrive } from "react-icons/md";
import { selectIsIntegrationActive, useGetIntegrationsQuery } from "src/features/integrations/integrationsSlice";
import { showGoogleDriveInstructionsModal, useUploadToGoogleDriveMutation } from "src/features/googleDrive/googleDriveSlice";
import { useParams } from "react-router-dom";
import GoogleDriveInstructionsModal from "src/features/googleDrive/modals/GoogleDriveInstructionsModal";
import { useAppDispatch, useAppSelector } from "src/app/store/hooks";
import useAccount from "src/features/account/hooks/useAccount";
import { FeatureTypes } from "src/features/account/types";
import { AccountUpgradeModalTypeEnum } from "src/features/accountUpgrade/types";
import { ActionIcon } from "@mantine/core";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const GoogleDriveUploadButton = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { callId } = useParams();
    // @ts-ignore
    const fileIds = useAppSelector((state) => selectConversationFileIds(state, callId));
    const { isFeatureEnabled, upgradeAccount } = useAccount();
    const googleDriveFeatureEnabled = isFeatureEnabled(FeatureTypes.GoogleDriveIntegration);
    const [uploadToGoogleDrive, { isLoading: isUploading }] = useUploadToGoogleDriveMutation();
    const { isLoading: isLoadingIntegrations } = useGetIntegrationsQuery();
    const isGoogleDriveIntegrationActive = useAppSelector(state => selectIsIntegrationActive(state, 'googleDrive'));
    const handleAddToDrive = () => {
        if (!googleDriveFeatureEnabled) {
            return upgradeAccount(AccountUpgradeModalTypeEnum.GoogleDrive);
        }
        if (!isGoogleDriveIntegrationActive) {
            return dispatch(showGoogleDriveInstructionsModal());
        }
        uploadToGoogleDrive({ fileIds });
    };
    return (_jsxs(_Fragment, { children: [_jsx(GoogleDriveInstructionsModal, {}), _jsx(OverlayTrigger, { placement: "top", overlay: _jsx(Tooltip, { id: "google-drive-upload", children: t("Upload.transcript.to.Google.Drive_") }), children: _jsx(ActionIcon, { variant: "transparent", "aria-label": "GoogleDriveUploadButton", onClick: handleAddToDrive, loading: (!isGoogleDriveIntegrationActive && isLoadingIntegrations) || isUploading, children: _jsx(MdOutlineAddToDrive, { size: 18 }) }) })] }));
};
export default GoogleDriveUploadButton;
