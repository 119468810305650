import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectTranscriptsAsChat } from "src/features/transcripts/transcriptsSlice";
import { useLayoutEffect } from "react";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const useScrollToHighlightedTranscript = () => {
    const { callId } = useParams();
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const transcripts = useSelector((state) => selectTranscriptsAsChat(state, { fileId: fileIds, callId }));
    const { id } = transcripts.find(transcript => transcript.isHighlighted === true) || {};
    useLayoutEffect(() => {
        if (!id) {
            return;
        }
        const highlightedElement = document.querySelector(".transcripts-chat-row.highlighted");
        if (!highlightedElement) {
            return;
        }
        highlightedElement.scrollIntoView({ behavior: "smooth" });
    }, [id]);
};
export default useScrollToHighlightedTranscript;
