import { WEB_API_URL_FULL } from "src/app/config";
import { SETTINGS_GUIDE_3CX_ROUTE } from "src/app/router/config";
export const VoIpServerTypes = {
    '3CX': '3CX',
    FreePBX: 'FreePBX',
    FreeSwitch: 'FreeSwitch',
    Asterisk: 'Asterisk',
    Other: 'Other',
};
export const VoIpServerGuideRoutes = {
    '3CX': SETTINGS_GUIDE_3CX_ROUTE,
    FreePBX: `${WEB_API_URL_FULL}/docs/demos/#freepbx`,
    FreeSwitch: `${WEB_API_URL_FULL}/docs/demos/#freeswitch`,
    Asterisk: `${WEB_API_URL_FULL}/docs/demos/#asterisk`,
    Other: `${WEB_API_URL_FULL}/docs/demos/#auto-uploader`,
};
export const VoIpServerTypesTranslationKeys = {
    '3CX': '3cx_',
    FreePBX: 'freepbx_',
    FreeSwitch: 'freeswitch_',
    Asterisk: 'asterisk_',
    Other: 'other_',
};
