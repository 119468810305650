import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { getConversationTitle } from "src/features/auth/util/common";
import AudioTitlePopover from "src/features/conversation/audioPlayer/AudioTitlePopover";
import { BifIconPencil, OverlayTrigger } from "banafo-design-system";
import { useGetCallQuery } from "src/features/conversations/conversationApi";
import { useState } from "react";
const AudioTitle = () => {
    const { callId } = useParams();
    const [show, setShow] = useState(false);
    const { data: conversation = {} } = useGetCallQuery(callId);
    const handleClick = (event) => {
        setShow(!show);
    };
    if (!conversation) {
        return;
    }
    return (_jsx(OverlayTrigger, { show: show, trigger: "click", placement: "bottom", rootClose: true, onToggle: (nextShow) => setShow(nextShow), overlay: _jsx(AudioTitlePopover, { title: getConversationTitle(conversation), onHide: () => setShow(false) }), children: _jsxs("div", { className: "d-flex align-items-center", onClick: handleClick, children: [_jsx(BifIconPencil, {}), _jsx("h6", { className: "ms-50 mb-0 text-truncate", children: getConversationTitle(conversation) })] }) }));
};
export default AudioTitle;
