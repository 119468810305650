import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import TranscriptsChatList from "src/features/transcripts/TranscriptsChatList";
import { resetInitialActiveTranscriptId, selectInitialActiveTranscriptId, selectActiveTranscriptId, selectTranscriptsAsChat, setActiveTranscriptId, setInitialActiveTranscriptId } from "src/features/transcripts/transcriptsSlice";
import { seekAudio } from "src/features/auth/store/actions/playback";
import { FILE_AUTO_DELETED_ERROR } from "src/features/conversations/audioPlayer/errors";
import { selectConversationFileIds } from "src/features/conversations/conversationApi";
const TranscriptsChatListContainer = () => {
    const { callId, transcriptId } = useParams();
    const initialActiveTranscriptId = useSelector(selectInitialActiveTranscriptId);
    const fileIds = useSelector((state) => selectConversationFileIds(state, callId));
    const transcripts = useSelector((state) => selectTranscriptsAsChat(state, { fileId: fileIds, callId }));
    const dispatch = useDispatch();
    const { ready: playbackReady, duration: playbackDuration, error: playbackError } = useSelector(state => state.app.playback);
    useEffect(() => {
        if (!transcriptId)
            return;
        dispatch(setInitialActiveTranscriptId(transcriptId));
    }, []);
    useEffect(() => {
        if (!initialActiveTranscriptId)
            return;
        const transcript = transcripts.find(transcript => transcript.id === initialActiveTranscriptId);
        if (!transcript)
            return;
        const { matched, startedAt } = transcript;
        // click only on matched elements or оn ungrouped transcripts (then matched is undefined)
        if (matched === false) {
            return;
        }
        if (!playbackReady && playbackError === FILE_AUTO_DELETED_ERROR) {
            dispatch(setActiveTranscriptId(initialActiveTranscriptId));
            dispatch(resetInitialActiveTranscriptId());
        }
        if (!playbackReady)
            return;
        const duration = playbackDuration * 1000;
        const position = startedAt / duration > 1 ? 1 : startedAt / duration;
        dispatch(seekAudio(position));
        dispatch(resetInitialActiveTranscriptId());
    }, [playbackReady, initialActiveTranscriptId]);
    return _jsx(TranscriptsChatList, { transcripts: transcripts, className: "mt-150" });
};
export default TranscriptsChatListContainer;
